import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { setToken } from "../redux/slices/tokenSlice";
import { setUser } from "../redux/slices/userSlice";
import { useSnackbar } from "notistack";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CloseIcon from "@mui/icons-material/Close";
import CircularProgress from "@mui/material/CircularProgress";
import ShowIcon from "@mui/icons-material/Visibility";
import HideIcon from "@mui/icons-material/VisibilityOff";
import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

/* ModalComponent */
export default function ModalComponent({ show, setShow }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [value, setValue] = useState(0);
  const [userName, setUsername] = useState("");
  const [acceptPrivacy, setAcceptPrivacy] = useState(false);
  const [acceptRules, setAcceptRules] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [userRegister, setUserRegister] = useState("");
  const [userRegisterName, setUserRegisterName] = useState("");
  const [userRegisterPassword, setUserRegisterPassword] = useState("");
  const [userRegisterConfirmPassword, setUserRegisterConfirmPassword] =
    useState("");
  const [showLoginPass, setShowLoginPass] = useState(false);
  const [showRegisterPass, setShowRegisterPass] = useState(false);
  const [showRegisterConfirmPass, setShowRegisterConfirmPass] = useState(false);
  const [update, setUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [passLength, setPassLength] = useState(false);
  const [alphaNum, setAlphaNum] = useState(false);
  const [passUpper, setPassUpper] = useState(false);
  const [passLower, setPassLower] = useState(false);
  const [passNumber, setPassNumber] = useState(false);
  const [successRegister, setSuccessRegister] = useState(false);
  const [successLogin, setSuccessLogin] = useState(false);
  const [recovEmail, setRecovEmail] = useState("");
  const registerCondition =
    userRegister.length &&
    userRegisterPassword.length &&
    userRegisterConfirmPassword.length &&
    acceptPrivacy &&
    acceptRules;
  const dispatch = useDispatch();
  //post url
  const url = process.env.REACT_APP_MAIN_URL ?? process.env.PUBLIC_URL;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function setIndex(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  async function submitLogin(e) {
    e.preventDefault();
    setSuccessLogin(false);
    setLoading(true);

    const userLogin = {
      email: userName,
      password: userPassword,
    };
    const userToRedux = {
      email: userName,
    };
    try {
      const response = await fetch(url + "api/Account/login", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userLogin),
      });
      const data = await response.json();
      if (response.ok) {
        sessionStorage.setItem("CvChallenge", data.token);
        userToRedux.admin = data.isAdmin;
        userToRedux.token = data.token;
        dispatch(setToken(data.token));
        dispatch(setUser(userToRedux));
        document.getElementById("formLogin").reset();

        navigate("/dashboard");
        setUpdate(true);
        return;
      } else {
        enqueueSnackbar(`${t("toastError")}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      }

      return;
    } catch (error) {
      enqueueSnackbar(`${(t("toastError"), error)}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    } finally {
      setSuccessLogin(true);
      setLoading(false);
    }
  }

  async function handleRegister(e) {
    e.preventDefault();
    const passWordPAttern =
      /(?=^.{14,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/;
    if (userRegisterPassword !== userRegisterConfirmPassword) {
      enqueueSnackbar(`${t("passNoMatch")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
      return;
    }
    if (!passWordPAttern.test(userRegisterPassword)) {
      enqueueSnackbar(`${t("passNoMatchPrerequesites")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
      return;
    }

    setSuccessRegister(false);
    setLoading(true);

    let user = {
      email: userRegister,
      name: userRegisterName,
      password: userRegisterPassword,
      secondPassword: userRegisterConfirmPassword,
      acceptTerms: acceptPrivacy,
      acceptedRules: acceptRules,
    };

    /* Post request */
    try {
      const response = await fetch(url + "api/Account", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(user),
      });
      const data = response.json();
      if (response.ok) {
        sessionStorage.setItem("CvChallenge", data.token);
        document.getElementById("formRegister").reset();
        enqueueSnackbar(`${t("toastConfirm")}`, {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
        setValue(0);
      } else {
        enqueueSnackbar(`${t("toastError")}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      }
      return;
    } catch (error) {
      enqueueSnackbar(`${t("toastError")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    } finally {
      setSuccessRegister(true);
      setLoading(false);
    }
  }
  async function handleRecovery(e) {
    e.preventDefault();
    setSuccessLogin(false);
    setLoading(true);

    try {
      const response = await fetch(url + "api/Account/forgotPassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(recovEmail),
      });
      if (response.ok) {
        document.getElementById("forgotPass").reset();
        setRecovEmail("");
        setLoading(false);
        setUpdate(true);
        enqueueSnackbar(`${t("toastRecoverySuccess")}`, {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
        setShow(false);
      } else {
        enqueueSnackbar(`${t("toastError")}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      }
      return;
    } catch (error) {
      enqueueSnackbar(`${(t("toastError"), error)}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    } finally {
      setSuccessLogin(true);
      setLoading(false);
    }
  }
  let confirmPassSteps = () => {
    const passLengthRegex = /(^.{14,}$)/;
    const passNumberRegex = /[0-9]/;
    const alphaNumRegex = /[^A-Za-z0-9]/;
    const upperPassRegex = /[A-Z]/;
    const lowerPassRegex = /[a-z]/;
    if (passLengthRegex.test(userRegisterPassword)) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
    if (passNumberRegex.test(userRegisterPassword)) {
      setPassNumber(true);
    } else {
      setPassNumber(false);
    }
    if (alphaNumRegex.test(userRegisterPassword)) {
      setAlphaNum(true);
    } else {
      setAlphaNum(false);
    }
    if (lowerPassRegex.test(userRegisterPassword)) {
      setPassLower(true);
    } else {
      setPassLower(false);
    }
    if (upperPassRegex.test(userRegisterPassword)) {
      setPassUpper(true);
    } else {
      setPassUpper(false);
    }
  };

  useEffect(() => {
    confirmPassSteps();
  }, [update, userRegisterPassword]);

  return (
    <Modal
      hideBackdrop
      open={show}
      onClose={setShow}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      sx={{ width: "100%", height: "100%" }}
    >
      <Box
        sx={{
          ...style,
          width: { xs: "90%", sm: "50%" },
          height: "max-content",
        }}
      >
        <Box
          sx={{
            borderBottom: 1,
            borderColor: "divider",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color: "black",
          }}
        >
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="login and register tab"
            textColor="inherit"
            TabIndicatorProps={{ style: { background: "black" } }}
          >
            <Tab sx={{ color: "black" }} label="Login" {...setIndex(0)} />
            <Tab label="Register" {...setIndex(1)} />
            {/* <Tab label={""} {...setIndex(2)} /> */}
          </Tabs>
          <CloseIcon onClick={setShow} />
        </Box>
        <Box
          sx={{
            width: "60%",
            display: "flex",
            justifyContent: "center",
            margin: "0 auto",
            minHeight: "max-content",
          }}
        >
          {value === 0 && (
            <Box
              className={"panel-box"}
              sx={{
                display: "flex",
                py: "100px",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                height: "30%",
                gap: "20px",
              }}
            >
              <form
                id="formLogin"
                onSubmit={submitLogin}
                style={{ width: "100%" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                    position: "relative",
                  }}
                >
                  <TextField
                    autoComplete="email"
                    required
                    value={userName}
                    sx={{ width: "100%" }}
                    id="email"
                    label="Email"
                    type="email"
                    variant="standard"
                    onChange={(event) => setUsername(event.target.value)}
                  />
                  <EmailIcon sx={{ position: "absolute", right: 0 }} />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                    position: "relative",
                  }}
                >
                  <TextField
                    autoComplete="current-password"
                    required
                    value={userPassword}
                    sx={{ width: "100%" }}
                    id="password"
                    label="Password"
                    type={showLoginPass ? "text" : "password"}
                    variant="standard"
                    onChange={(event) => setUserPassword(event.target.value)}
                  />
                  {showLoginPass ? (
                    <ShowIcon
                      onClick={() => setShowLoginPass(!showLoginPass)}
                      sx={{ position: "absolute", right: 0 }}
                    />
                  ) : (
                    <HideIcon
                      onClick={() => setShowLoginPass(!showLoginPass)}
                      sx={{ position: "absolute", right: 0 }}
                    />
                  )}
                </Box>
                <Box sx={{ my: 3, display: "flex", justifyContent: "center" }}>
                  <Typography
                    sx={{
                      cursor: "pointer",
                      fontSize: "10px",
                      textDecoration: "underline",
                    }}
                    onClick={() => setValue(2)}
                  >
                    {t("recoveryTab")}
                  </Typography>
                </Box>

                <Box sx={{ position: "relative" }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      transition: "0.8s",
                      border: "2px solid black",
                      minWidth: 200,

                      backgroundColor: "black",
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: "black",
                        transition: "0.8s",
                        color: "FFD526",

                        "& p": { color: "gray !important" },
                      },
                    }}
                    disabled={loading}
                    onClick={submitLogin}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "900",
                        fontFamily: "Montserrat",
                        textTransform: "lowercase",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      Login
                    </Typography>
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </form>
            </Box>
          )}
          {value === 1 && (
            <Box
              className={"panel-box"}
              sx={{
                display: "flex",

                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                gap: "20px",
              }}
            >
              <form
                id="formRegister"
                action="/authentication/register"
                //method="post"
                //onSubmit={handleRegister}
                style={{ width: "100%" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                    position: "relative",
                  }}
                >
                  <TextField
                    required={true}
                    value={userRegisterName}
                    autoComplete="Name"
                    sx={{ width: "100%" }}
                    id="registerName"
                    label={t("name")}
                    type="text"
                    variant="standard"
                    onChange={(e) => setUserRegisterName(e.target.value)}
                  />
                  <PersonIcon sx={{ position: "absolute", right: 0 }} />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                    position: "relative",
                  }}
                >
                  <TextField
                    required={true}
                    value={userRegister}
                    autoComplete="email"
                    sx={{ width: "100%" }}
                    id="registerEmail"
                    label="Email"
                    type="email"
                    variant="standard"
                    onChange={(e) => setUserRegister(e.target.value)}
                  />
                  <EmailIcon sx={{ position: "absolute", right: 0 }} />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                    position: "relative",
                  }}
                >
                  <TextField
                    required={true}
                    value={userRegisterPassword}
                    autoComplete="new-password"
                    sx={{ width: "100%" }}
                    id="registerPassword"
                    label="Password"
                    type={showRegisterPass ? "text" : "password"}
                    variant="standard"
                    onChange={(e) => setUserRegisterPassword(e.target.value)}
                  />
                  {showRegisterPass ? (
                    <ShowIcon
                      onClick={() => setShowRegisterPass(!showRegisterPass)}
                      sx={{ position: "absolute", right: 0 }}
                    />
                  ) : (
                    <HideIcon
                      onClick={() => setShowRegisterPass(!showRegisterPass)}
                      sx={{ position: "absolute", right: 0 }}
                    />
                  )}
                </Box>

                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                    position: "relative",
                  }}
                >
                  <TextField
                    required={true}
                    value={userRegisterConfirmPassword}
                    autoComplete="new-password"
                    sx={{ width: "100%" }}
                    id="registerConfirmPassword"
                    label={t("newPassConfirm")}
                    type={showRegisterConfirmPass ? "text" : "password"}
                    variant="standard"
                    onChange={(e) =>
                      setUserRegisterConfirmPassword(e.target.value)
                    }
                  />
                  {showRegisterConfirmPass ? (
                    <ShowIcon
                      onClick={() =>
                        setShowRegisterConfirmPass(!showRegisterConfirmPass)
                      }
                      sx={{ position: "absolute", right: 0 }}
                    />
                  ) : (
                    <HideIcon
                      onClick={() =>
                        setShowRegisterConfirmPass(!showRegisterConfirmPass)
                      }
                      sx={{ position: "absolute", right: 0 }}
                    />
                  )}
                </Box>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: "10px",
                    color: "gray",
                  }}
                >
                  {t("passConditions")}
                  <ul>
                    <li style={{ color: passLength ? "green" : "red" }}>
                      {t("passCharacters")}
                    </li>
                    <li style={{ color: alphaNum ? "green" : "red" }}>
                      {t("passAlfanumeric")}
                    </li>
                    <li style={{ color: passNumber ? "green" : "red" }}>
                      {t("passDigit")}{" "}
                    </li>
                    <li style={{ color: passUpper ? "green" : "red" }}>
                      {t("passUppercase")}{" "}
                    </li>
                    <li style={{ color: passLower ? "green" : "red" }}>
                      {t("passLowercase")}{" "}
                    </li>
                  </ul>
                </Typography>
                <Box sx={{ mb: 5 }}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      value={acceptPrivacy}
                      onChange={() => setAcceptPrivacy(!acceptPrivacy)}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontSize: "0.7rem",
                      }}
                    >
                      {t("acceptPrivacy")}{" "}
                      <a
                        style={{ cursor: "pointer" }}
                        href="/politicas-de-privacidade"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t("privacyPolicy")}
                      </a>
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Checkbox
                      value={acceptRules}
                      onChange={() => setAcceptRules(!acceptRules)}
                    />
                    <Typography
                      sx={{
                        fontFamily: "Montserrat",
                        fontSize: "0.7rem",
                      }}
                    >
                      {t("acceptPrivacy")}{" "}
                      <a
                        style={{ cursor: "pointer" }}
                        href="/agap2work"
                        target={"_blank"}
                        rel="noopener noreferrer"
                      >
                        {t("regulationName")}
                      </a>
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ position: "relative", display: "inline-block" }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      transition: "0.8s",
                      border: "2px solid black",
                      minWidth: 200,

                      backgroundColor: "black",
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: "black",
                        transition: "0.8s",
                        color: "FFD526",

                        "& p": { color: "gray !important" },
                      },
                    }}
                    disabled={loading || !registerCondition}
                    onClick={handleRegister}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "900",
                        fontFamily: "Montserrat",
                        textTransform: "lowercase",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      {t("register")}
                    </Typography>
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </form>
            </Box>
          )}
          {value === 2 && (
            <Box
              className={"panel-box"}
              sx={{
                display: "flex",
                py: "50px",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                height: "30%",
                gap: "20px",
              }}
            >
              <form
                id="forgotPass"
                //onSubmit={submitLogin}
                style={{ width: "100%" }}
              >
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    alignItems: "end",
                    my: 10,
                  }}
                >
                  <TextField
                    required
                    value={recovEmail}
                    sx={{ width: "100%" }}
                    id="email"
                    label="Email"
                    type="email"
                    variant="standard"
                    onChange={(event) => setRecovEmail(event.target.value)}
                  />
                </Box>

                <Box sx={{ position: "relative" }}>
                  <Button
                    variant="contained"
                    sx={{
                      width: "100%",
                      transition: "0.8s",
                      border: "2px solid black",
                      minWidth: 200,

                      backgroundColor: "black",
                      borderRadius: "5px",
                      "&:hover": {
                        backgroundColor: "transparent",
                        borderColor: "black",
                        transition: "0.8s",
                        color: "FFD526",

                        "& p": { color: "gray !important" },
                      },
                    }}
                    disabled={loading}
                    onClick={handleRecovery}
                  >
                    <Typography
                      sx={{
                        color: "white",
                        fontWeight: "900",
                        fontFamily: "Montserrat",
                        textTransform: "lowercase",
                        "&:hover": {
                          color: "black",
                        },
                      }}
                    >
                      {t("recover")}
                    </Typography>
                  </Button>
                  {loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: "blue",
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        marginTop: "-12px",
                        marginLeft: "-12px",
                      }}
                    />
                  )}
                </Box>
              </form>
            </Box>
          )}
        </Box>
      </Box>
    </Modal>
  );
}
