import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

export default function PopUp({
  closePopUp,
  element,
  active,
  removeElements,
  setReloadDB,
}) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [motiveValue, setMotiveValue] = useState("");
  const userFromRedux = useSelector((state) => state.user.value);
  const token = userFromRedux.token;

  const url = process.env.REACT_APP_MAIN_URL ?? process.env.PUBLIC_URL;

  const handleClosePopUp = () => {
    removeElements([]);
    closePopUp(false);
  };

  const handleSubmit = async () => {
    let status = active;
    let toSend = {
      cvInformationId: element,
      status: active,
      declinedReason: motiveValue,
    };
    const bearer = "Bearer " + token;
    if (status === 1 && !motiveValue) {
      enqueueSnackbar(`${t("popupMotive")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
      return;
    }

    try {
      const response = await fetch(url + "api/CvInformation", {
        method: "PUT",
        headers: {
          Accept: "*/*",
          "Content-type": "application/json",
          Authorization: bearer,
        },
        body: JSON.stringify(toSend),
      });
      if (response.ok) {
        closePopUp(false);
        setReloadDB(true);
      }
    } catch (error) {
      enqueueSnackbar(`${t("toastError")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    }
  };
  return (
    <Box
      sx={{
        position: "absolute",
        backgroundColor: "rgba(255, 255, 255, 0.2)",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "45%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          backgroundColor: "white",
          display: "flex",
          gap: "30px",
          width: "50%",
          height: "30%",
          p: "20px",
        }}
      >
        <CloseIcon
          sx={{ position: "fixed", right: "20px" }}
          onClick={handleClosePopUp}
        />
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "100px",
          }}
        >
          <TextField
            id="motive"
            label="Write here the deny motive"
            variant="filled"
            value={motiveValue}
            onChange={(e) => setMotiveValue(e.target.value)}
            sx={{
              width: "50%",
              backgroundColor: "rgba(255, 255, 255, 0.5)",
              color: "white",
            }}
            disabled={active !== 1 && true}
            required={active !== 1 && true}
          />
          <Button
            onClick={handleSubmit}
            sx={{ width: "30%" }}
            variant="outlined"
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
