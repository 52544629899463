import React from "react";
import SimpleNav from "./../components/core/SimpleNav";
import { Layout } from "./../components/Layout";
import Footer from "../components/core/Footer";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import Banner from "../assets/images/denuncia-3x.jpg";

const Regulamento = () => {
  return (
    <Layout>
      <SimpleNav />
      <Box sx={{ p: "20px" }}>
        <div class="modal-header">
          <img class="w-100" src={Banner} />
        </div>
        <div class="modal-body">
          <section class="job-offer-container">
            <h2 class="modal-title" id="modalTitle">
              Regulamento de Comunica&#231;&#227;o de Irregularidades
            </h2>
            <div class="container-fluid">
              <div class="row">
                <div class="col" style={{ paddingBottom: "300px" }}>
                  <p>
                    <strong>(Canal de Denúncia)</strong>
                  </p>
                  <p> </p>
                  <h2>Introdução</h2>
                  <p>
                    O presente documento estabelece (e consolida) o Regulamento
                    de proteção que abriga/aplica-se a denunciantes que tenham
                    obtido informações sobre violações em contexto profissional,
                    sejam eles colaboradores, clientes, fornecedores ou órgãos
                    de administração.
                  </p>
                  <p>
                    Este documento aplica um conjunto de normas que asseguram
                    uma proteção eficaz dos Denunciantes relativamente aos atos
                    e domínios de intervenção. Determina medidas de proteção,
                    além de regras e procedimentos internos para a receção e
                    tratamento da comunicação de irregularidades, em
                    conformidade com as disposições legais aplicáveis, bem como
                    as regras, princípios e valores da agap2IT.
                  </p>
                  <h2>Condições para integração no canal de Denúncia</h2>
                  <p>
                    Os denunciantes beneficiam da proteção do Regulamento desde
                    que:
                  </p>
                  <ul>
                    <li>o denunciante esteja de Boa Fé;</li>
                    <li>
                      tenham tido motivos razoáveis para acreditar que as
                      informações sobre violações comunicadas são verdadeiras no
                      momento em que foram transmitidas e que estão abrangidas
                      pelo âmbito de aplicação;
                    </li>
                    <li>
                      as pessoas que de forma anónima comunicaram ou divulgaram
                      publicamente informações sobre violações, mas que
                      posteriormente tenham sido identificadas e alvo de
                      retaliação;
                    </li>
                  </ul>
                  <h2>Âmbito de Aplicação Pessoal</h2>
                  <p>O Canal de Denúncia aplica-se e abriga:</p>
                  <ul>
                    <li>Colaboradores</li>
                    <li>Clientes</li>
                    <li>Fornecedores</li>
                    <li>
                      Órgãos de administração ou titulares de participações
                      sociais
                    </li>
                    <li>Órgãos de gestão ou de supervisão</li>
                    <li>
                      Quaisquer pessoas que trabalhem sob a supervisão e a
                      direção de contratantes, subcontratantes e fornecedores da
                      agap2IT
                    </li>
                    <li>
                      Denunciantes nos casos em que comuniquem ou divulguem,
                      publicamente informações sobre violações obtidas numa
                      relação profissional que tenha, entretanto, terminado.
                    </li>
                  </ul>
                  <p>
                    A proteção conferida pela presente lei é extensível, com as
                    devidas adaptações, a:
                  </p>
                  <ul>
                    <li>
                      Pessoa Singular que auxilie o denunciante no procedimento
                      de denúncia e cujo auxílio deva ser confidencial
                      (Facilitadores)
                    </li>
                    <li>
                      Terceiros que estejam ligados ao denunciante (colega de
                      trabalho, familiar que possa ser alvo de retaliação num
                      contexto profissional...)
                    </li>
                    <li>
                      Pessoas coletivas ou entidades equiparadas que sejam
                      detidas ou controladas pelo denunciante, para as quais o
                      denunciante trabalhe ou com as quais esteja de alguma
                      forma ligado num contexto profissional
                    </li>
                  </ul>
                  <h2>Objetivos do Canal de Denúncia</h2>
                  <p>
                    Consideram-se irregularidades abrangidas no presente
                    Regulamento, os atos nos seguintes domínios:
                  </p>
                  <ul>
                    <li>Contratação Pública;</li>
                    <li>
                      Serviços, Produtos e mercados financeiros e prevenção do
                      branqueamento de capitais e do financiamento do
                      terrorismo;
                    </li>
                    <li>Segurança e conformidade dos produtos;</li>
                    <li>Segurança dos Transportes;</li>
                    <li>Proteção do Ambiente;</li>
                    <li>Danos ao meio ambiente;</li>
                    <li>Proteção contra radiações e segurança nuclear;</li>
                    <li>
                      Segurança dos géneros alimentícios e dos alimentos para
                      animais, saúde e bem-estar animal;
                    </li>
                    <li>Saúde Pública;</li>
                    <li>Defesa do Consumidor;</li>
                    <li>
                      Proteção da Privacidade dos dados pessoais e segurança da
                      rede e dos sistemas de Informação;
                    </li>
                    <li>
                      Violações lesivas dos Interesses financeiros da União
                      Europeia;
                    </li>
                    <li>
                      Violações relacionadas com o mercado interno incluindo as
                      regras de concorrência e auxílios estatais, bem como as
                      regras de fiscalidade societária;
                    </li>
                    <li>
                      A criminalidade violenta, especialmente violenta e
                      altamente organizada. Bem como a criminalidade organizada
                      e económico-financeira (por exemplo: corrupção
                      ativa/passiva);
                    </li>
                  </ul>
                  <p> </p>
                  <h2>Modo de Comunicação</h2>
                  <p>
                    O presente Regulamento tem subjacente um regime de
                    comunicação voluntário de irregularidades, cujo sistema de
                    receção, processamento e tratamento da comunicação de
                    irregularidades, opera através de canais de comunicação
                    dedicados a esse fim, promovendo a total integridade,
                    confidencialidade da identidade ou anonimato dos
                    denunciantes além da confidencialidade da identidade de
                    terceiros mencionados na denúncia, garantindo o impedimento
                    do acesso de pessoas não autorizadas.
                  </p>
                  <p>
                    A apresentação de denúncias poderá ser feita de forma
                    anónima (neste caso, terá que ser pedido o anonimato pelo
                    Denunciante aquando do pedido) ou mantendo a
                    confidencialidade da identidade do denunciante pelo pessoal
                    autorizado ao tratamento da denúncia, podendo em qualquer
                    caso ser apresentada de forma escrita e/ou verbal. Em todo o
                    caso, a denúncia escrita (em caso de forma escrita) e/ou o
                    pedido de contacto para denúncia (em caso de denúncia
                    verbal) deverá ser remetido para o email:
                  </p>
                  <ul>
                    <li>
                      Endereço de e-mail:{" "}
                      <a href="mailto:etica@agap2.pt">etica@agap2.pt</a>
                    </li>
                  </ul>
                  <p> </p>
                  <h2>Tratamento da Comunicação</h2>
                  <p>
                    Nos termos definidos pelo presente Regulamento, todas as
                    comunicações de irregularidades são tratadas como informação
                    confidencial, tendo o canal de denúncia, pessoas designadas
                    (que estão sujeitas a cláusulas de imparcialidade), estando
                    encarregues de receber a denúncia e manter a comunicação com
                    o denunciante, se necessário, solicitar mais informações e
                    dar ao denunciante retorno da informação comprometendo-se
                    ainda a prestar essa informação num prazo razoável.
                  </p>
                  <p> </p>
                  <h2>Pessoal Autorizado ao Tratamento</h2>
                  <p>
                    O Canal de Denúncia, dada a criticidade da informação, adota
                    o princípio do need to know, restringindo e diferenciando
                    entre: o conhecimento da denúncia; e a identidade do
                    denunciante. Só o pessoal autorizado encarregue do
                    tratamento da denúncia poderá ter conhecimento da identidade
                    do denunciante.
                  </p>
                  <p>
                    Há um responsável pelo cumprimento normativo que exerce as
                    suas funções de modo independente, permanente e com
                    autonomia decisória, dispondo da informação interna e dos
                    meios humanos e técnicos necessários ao bom desempenho da
                    sua função.
                  </p>
                  <p>
                    Nos termos da legislação em vigor, este responsável pelo
                    cumprimento normativo é transversal entre as sociedades do
                    grupo MoOngy S.A.
                  </p>
                  <p>
                    Por forma a preservar a integridade, limitando a informação,
                    mantendo uma maior independência do funcionamento do canal,
                    a identidade do <em>Compliance Officer</em> responsável pela
                    manutenção, preservação e integridade do canal será mantida
                    em anonimato para o grupo, transmitindo-se apenas que será
                    assegurado por pessoal específico e destacado exclusivamente
                    para o canal, sendo os elementos com formação no Canal de
                    Denúncia parte do DPG.
                  </p>
                  <p>
                    O <em>Compliance Officer </em>assim como o Pessoal
                    autorizado, está submetido a NDA específico referente ao
                    Canal de Denúncia, por forma a salvaguardar sob um estrito
                    dever de confidencialidade qualquer denúncia.
                  </p>
                  <p> </p>
                  <h2>Processo de Averiguação</h2>
                  <p>
                    A comunicação/denúncia será enviada internamente ao
                    responsável pelo canal de denúncia. Este deverá determinar
                    se a comunicação de irregularidade contém fundamentos
                    mínimos para se iniciar um processo de averiguação.
                  </p>
                  <p>
                    A receção de uma comunicação/denúncia dará sempre lugar a um
                    processo de averiguações, exceto se se verificar
                    manifestamente a sua falta de fundamento.
                  </p>
                  <p>
                    O pessoal autorizado que ficará encarregue do processo de
                    averiguação deverá promover a implementação de medidas
                    adequadas à proteção da Informação e dos dados contidos nas
                    comunicações e respetivos registos, bem como promover as
                    ações necessárias à confirmação inicial dos fundamentos.
                  </p>
                  <p>
                    A agap2IT compromete-se a informar ao autor da comunicação,
                    num prazo razoável (não pode exceder os três meses após
                    notificação remetida ao denunciante), sobre as medidas
                    previstas ou tomadas para dar seguimento à denúncia e os
                    motivos que justificam a escolha desse seguimento, assim
                    como as conclusões da averiguação efetuada.
                  </p>
                  <p>
                    O processo de averiguação termina com a documentação dos
                    resultados, fundamentos e conclusões além da formulação de
                    recomendações e medidas adequadas à situação, de outro modo,
                    caso seja considerado sem fundamento (por escassez ou
                    invalidade), a denúncia será arquivada.
                  </p>
                  <p> </p>
                  <h2>
                    <strong>Medidas de Proteção</strong>
                  </h2>
                  <h2>Proibição de Retaliação</h2>
                  <p>
                    A Denúncia não pode implicar, a título algum, qualquer ato
                    de retaliação (integram-se igualmente, as ameaças ou
                    tentativas), ou omissão que de forma direta ou indireta,
                    ocorrendo em contexto profissional e motivado por uma
                    denúncia cause ou possa causar ao denunciante ou a
                    facilitadores da denúncia, de modo injustificado, danos
                    patrimoniais ou não patrimoniais tais como assédio,
                    intimação ou discriminação, devendo a agap2IT velar para que
                    tal não ocorra.
                  </p>
                  <p>
                    Aquele que praticar um ato de retaliação indemniza o
                    denunciante pelos danos causados, o denunciante pode
                    requerer as providências adequadas às circunstâncias do
                    caso, com o fim de evitar a verificação ou a expansão dos
                    danos.
                  </p>
                  <p>
                    A sanção disciplinar aplicada ao denunciante até dois anos
                    após a denúncia ou divulgação pública presume-se abusiva,
                    todos os elencados na secção{" "}
                    <a href="#_Âmbito_de_Aplicação">
                      <em>“âmbito de aplicação pessoal”</em>
                    </a>{" "}
                    encontram-se abrangidos por esta proteção.
                  </p>
                  <p> </p>
                  <h2>Exceção devido a necessidade de partilha</h2>
                  <p>
                    Se por algum motivo a identidade do denunciante necessitar
                    impreterivelmente de ser facultada a mais alguma parte ao
                    longo do processo da investigação, o denunciante receberá um
                    pedido de consentimento (com o motivo pelo qual se considera
                    como uma obrigação necessária e proporcional o conhecimento
                    da identidade) a ser dado pelo próprio, por forma a que este
                    permita (ou não permita) de livre vontade, a partilha da
                    identidade à parte a que a informação seja concebível como
                    essencial, para a investigação, por forma a salvaguardar os
                    direitos de defesa da pessoa visada.
                  </p>
                  <p>
                    {" "}
                    Importa referir, este pedido de consentimento será
                    solicitado ao denunciante, um por cada individuo (de forma
                    singular) a que se tenha como essencial a partilha. Nos
                    termos da legislação relativa ao Canal de Denúncia,
                    estabelece-se como exceção a situação de tal informação de
                    divulgação da identidade comprometer as investigações ou
                    processos judiciais no contexto de uma investigação por
                    autoridades nacionais, nesta situação em específico
                    derroga-se o consentimento.
                  </p>
                  <p> </p>
                  <h2>Medidas de Apoio</h2>
                  <p>
                    O Denunciante tem direito nos termos gerais, a proteção
                    jurídica, podendo beneficiar de medidas para proteção de
                    testemunhas em processo penal. Compete à Agap2it reconhecer
                    através de certificação o estatuto ao Denunciante.
                  </p>
                  <p> </p>
                  <h2>Dados Pessoais</h2>
                  <p>
                    Importa ter em conta que a confidencialidade da identidade
                    do denunciante é também aplicável à identidade das pessoas
                    elencadas no{" "}
                    <a href="#_Âmbito_de_Aplicação">
                      <em>“âmbito de aplicação pessoal”</em>
                    </a>
                    <em>.</em>
                  </p>
                  <p>
                    A informação recolhida ao abrigo do canal de denúncia será
                    utilizada exclusivamente para as finalidades nele previstas.
                    Para efeitos, a maxime proteção estabelecida nos termos do
                    Regulamento Geral de Proteção de Dados estará assegurada
                    para o tratamento de cada denúncia e principalmente da
                    Identidade do Denunciante.
                  </p>
                  <p>
                    Mormente, o Princípio da Minimização de Dados garantirá o
                    acesso do pessoal autorizado ao mínimo de informações
                    necessárias relativa à identidade do Denunciante e aos seus
                    Dados. Sem especificar, os restantes princípios (tais como
                    limitação do tratamento, responsabilidade...) e práticas do
                    RGPD serão aplicadas por forma a zelar pela proteção dos
                    dados do denunciante.
                  </p>
                  <p> </p>
                  <h2>Irrenunciabilidade dos Direitos</h2>
                  <p>
                    Por forma a garantir a integridade e frustrar quaisquer
                    impedimentos ou pressões de terceiros para com o denunciante
                    e restantes elencados, abrangidos pela proteção, o Direito
                    às Medidas de Proteção referidas é totalmente irrenunciável,
                    não podendo ser objeto de renúncia ou limitação por
                    quaisquer acordos, políticas, formas ou condições. São nulas
                    as disposições contratuais que limitem ou obstem à
                    apresentação ou seguimento de denúncias ou à divulgação
                    pública de infrações nos termos da presente lei.
                  </p>
                  <p> </p>
                  <h2>Captação da Denúncia</h2>
                  <p>
                    Os responsáveis pelo tratamento da denúncia têm o direito de
                    captar (com consentimento e escolha do denunciante do modo
                    de gravação, seja sob forma escrita – relatórios, atas - ou
                    verbal - por forma de captação de áudios ou outras formas de
                    multimédia)
                  </p>
                  <p> </p>
                  <h2>Produção e divulgação de informação fiável</h2>
                  <p>
                    A informação relativa à denúncia é conservada durante um
                    ano, por forma a ser integrada (mantendo a máxime
                    confidencialidade) no relatório sobre a atividade preventiva
                    da empresa, para avaliar a integração e operações do canal
                    de Denúncia, além de orientar a atividade preventiva da
                    empresa, racionalizando a alocação de meios disponíveis e
                    aumentando o nível de eficácia do sistema, por forma a
                    permitir a compreensão em termos globais e com a maior
                    aproximação possível dos contornos destes crimes e a
                    eficácia da sua investigação, além de escrutinar o tempo de
                    resposta global do canal de denúncia.
                  </p>
                  <p> </p>
                  <p>
                    O Regulamento de Comunicação de Irregularidades é revisto
                    anualmente após relatório anual de implementação e
                    funcionamento do Canal de Denúncia e é totalmente suportada
                    pela nossa gestão.
                  </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Box>
      <Footer />
    </Layout>
  );
};

export default Regulamento;
