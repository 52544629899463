import React, { useState, useEffect } from "react";
import Footer from "../components/core/Footer";
import SimpleNav from "../components/core/SimpleNav";
import { Box, Typography, TextField, Button } from "@mui/material";
import HomeImg from "./../assets/images/home_bg.png";
import ShowIcon from "@mui/icons-material/Visibility";
import HideIcon from "@mui/icons-material/VisibilityOff";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const Recovery = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [showUserPass, setShowUserPass] = useState(false);
  const [showUserConfirmPass, setShowUserConfirmPass] = useState(false);
  const [loading, setLoading] = useState(false);
  const [userPassword, setUserPassword] = useState("");
  const [userConfirmPassword, setUserConfirmPassword] = useState("");
  const [passLength, setPassLength] = useState(false);
  const [alphaNum, setAlphaNum] = useState(false);
  const [passUpper, setPassUpper] = useState(false);
  const [passLower, setPassLower] = useState(false);
  const [passNumber, setPassNumber] = useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const url = process.env.REACT_APP_MAIN_URL ?? process.env.PUBLIC_URL;
  const passWordPAttern =
    /(?=^.{14,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/;
  const buttonCondition = userConfirmPassword.length && userPassword.length;

  let handleApiRequest = async () => {
    const UrlQueryString = window.location.search;
    const queryParams = new URLSearchParams(UrlQueryString);
    let toSend = {
      email: queryParams.get("email"),
      token: queryParams.get("token").split(" ").join("+"),
      password: userPassword,
      secondPassword: userConfirmPassword,
    };

    if (userPassword !== userConfirmPassword) {
      enqueueSnackbar(`${t("passNoMatch")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
      return;
    }

    if (!passWordPAttern.test(userPassword)) {
      enqueueSnackbar(`${t("passNoMatchPrerequesites")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
      return;
    }

    try {
      const response = await fetch(url + `api/Account/ResetPassword`, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(toSend),
      });
      let data = await response.json();
      if (response.ok) {
        setLoading(false);
        enqueueSnackbar(`${t("changePassSuccess")}`, {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
        //redirecionar para homepage..
        navigate("/");
      } else {
        let errorFromDb = data[0].description;
        enqueueSnackbar(`${(t("toastError"), errorFromDb)}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar(`${(t("toastError"), error)}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    } finally {
    }
  };
  let confirmPassSteps = () => {
    const passLengthRegex = /(^.{14,}$)/;
    const passNumberRegex = /[0-9]/;
    const alphaNumRegex = /[^A-Za-z0-9]/;
    const upperPassRegex = /[A-Z]/;
    const lowerPassRegex = /[a-z]/;
    if (passLengthRegex.test(userPassword)) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
    if (passNumberRegex.test(userPassword)) {
      setPassNumber(true);
    } else {
      setPassNumber(false);
    }
    if (alphaNumRegex.test(userPassword)) {
      setAlphaNum(true);
    } else {
      setAlphaNum(false);
    }
    if (lowerPassRegex.test(userPassword)) {
      setPassLower(true);
    } else {
      setPassLower(false);
    }
    if (upperPassRegex.test(userPassword)) {
      setPassUpper(true);
    } else {
      setPassUpper(false);
    }
  };

  useEffect(() => {
    confirmPassSteps();
  }, [userPassword]);

  return (
    <Box
      sx={{
        minHeight: "90vh",
        backgroundImage: `url(${HomeImg})`,
        backgroundSize: "cover",
      }}
    >
      <SimpleNav />
      <Box
        sx={{
          p: "20px",
          maxWidth: { xs: "100%", md: "50%" },
          mt: { xs: "auto", md: "10%" },
          pl: "5%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            mt: "50px",
          }}
        >
          <form id="recoveryPass">
            <Box
              sx={{
                width: "100%",
                display: "flex",
                //alignItems: "end",
                gap: "30px",
              }}
            >
              <Box sx={{ width: "50%", position: "relative" }}>
                <TextField
                  required
                  value={userPassword}
                  sx={{ width: "100%" }}
                  id="password"
                  label="Password"
                  type={showUserPass ? "text" : "password"}
                  variant="standard"
                  onChange={(event) => setUserPassword(event.target.value)}
                />
                {showUserPass ? (
                  <ShowIcon
                    onClick={() => setShowUserPass(!showUserPass)}
                    sx={{ position: "absolute", right: 0, bottom: 0 }}
                  />
                ) : (
                  <HideIcon
                    onClick={() => setShowUserPass(!showUserPass)}
                    sx={{ position: "absolute", right: 0, bottom: 0 }}
                  />
                )}
              </Box>
              <Box sx={{ width: "50%", position: "relative" }}>
                <TextField
                  required
                  value={userConfirmPassword}
                  sx={{ width: "100%" }}
                  id="confirmPassword"
                  label="Confirm Password"
                  type={showUserConfirmPass ? "text" : "password"}
                  variant="standard"
                  onChange={(event) =>
                    setUserConfirmPassword(event.target.value)
                  }
                />
                {showUserConfirmPass ? (
                  <ShowIcon
                    onClick={() => setShowUserConfirmPass(!showUserConfirmPass)}
                    sx={{ position: "absolute", right: 0, bottom: 0 }}
                  />
                ) : (
                  <HideIcon
                    onClick={() => setShowUserConfirmPass(!showUserConfirmPass)}
                    sx={{ position: "absolute", right: 0, bottom: 0 }}
                  />
                )}
              </Box>
            </Box>
            <Box>
              <Typography
                variant="caption"
                sx={{
                  fontSize: "10px",
                  color: "gray",
                }}
              >
                {t("passConditions")}
                <ul>
                  <li style={{ color: passLength ? "green" : "red" }}>
                    {t("passCharacters")}
                  </li>
                  <li style={{ color: alphaNum ? "green" : "red" }}>
                    {t("passAlfanumeric")}
                  </li>
                  <li style={{ color: passNumber ? "green" : "red" }}>
                    {t("passDigit")}{" "}
                  </li>
                  <li style={{ color: passUpper ? "green" : "red" }}>
                    {t("passUppercase")}{" "}
                  </li>
                  <li style={{ color: passLower ? "green" : "red" }}>
                    {t("passLowercase")}{" "}
                  </li>
                </ul>
              </Typography>
            </Box>
            <Box sx={{ position: "relative" }}>
              <Button
                variant="contained"
                sx={{
                  width: "100%",
                  transition: "0.8s",
                  border: "2px solid black",
                  minWidth: 200,

                  backgroundColor: "black",
                  borderRadius: "5px",
                  "&:hover": {
                    backgroundColor: "transparent",
                    borderColor: "black",
                    transition: "0.8s",
                    color: "FFD526",

                    "& p": { color: "gray !important" },
                  },
                }}
                disabled={!buttonCondition || loading}
                onClick={handleApiRequest}
              >
                <Typography
                  sx={{
                    color: "white",
                    fontWeight: "900",
                    fontFamily: "Montserrat",
                    textTransform: "lowercase",
                    "&:hover": {
                      color: "black",
                    },
                  }}
                >
                  {t("recover")}
                </Typography>
              </Button>
            </Box>
          </form>
        </Box>
      </Box>

      <Footer relative={"relative"} />
    </Box>
  );
};

export default Recovery;
