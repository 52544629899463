import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PopUp from "./PopUp";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import DownloadIcon from "../../assets/icons/download.png";
import Paper from "@mui/material/Paper";
import { visuallyHidden } from "@mui/utils";
import { useTranslation } from "react-i18next";
import { CardMedia, Typography } from "@mui/material";

export default function EnhancedTable({ originalData, setReloadDB }) {
  const { t } = useTranslation();
  const userFromRedux = useSelector((state) => state.user.value);
  const admin = userFromRedux.admin;
  const token = userFromRedux.token;

  const [elementsChanged, setChangedElements] = useState(originalData);
  const [showPopUp, setShowPopUp] = useState(false);
  const [headItems, setHeadItems] = useState([]);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("createdDate");
  const [selected, setSelected] = useState(null);
  const [page, setPage] = useState(0);
  const [active, setActive] = useState(null);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const url = process.env.REACT_APP_MAIN_URL ?? process.env.PUBLIC_URL;

  const headCells = [
    {
      id: "user",
      numeric: false,
      disablePadding: true,
      label: "User",
    },
    {
      id: "candidate",
      numeric: false,
      disablePadding: false,
      label: `${t("candidate")}`,
    },
    {
      id: "motive",
      numeric: false,
      disablePadding: false,
      label: `${t("deniedMotive")}`,
    },
    {
      id: "status",
      numeric: false,
      disablePadding: false,
      label: `${t("status")}`,
    },
    {
      id: "consent",
      numeric: false,
      disablePadding: false,
      label: `${t("consent")}`,
    },
    {
      id: "consentDate",
      numeric: false,
      disablePadding: false,
      label: `${t("consentDate")}`,
    },
    {
      id: "createdDate",
      numeric: false,
      disablePadding: false,
      label: `${t("createdAt")}`,
    },
    {
      id: "cv",
      numeric: false,
      disablePadding: false,
      label: `${t("cv")}`,
    },
    {
      id: "actions",
      numeric: false,
      disablePadding: false,
      label: `${t("actions")}`,
    },
  ];

  const getCvFromDB = (arr, setNewArr) => {
    let newArr = [...arr];
    const bearer = "Bearer " + token;
    newArr.map(async (element) => {
      await fetch(url + `api/FileInformation?fileId=${element.fileId}`, {
        method: "GET",
        headers: {
          Accept: "*/*",
          Authorization: bearer,
        },
      })
        .then((resp) => {
          let response = resp.json();
          return response;
        })
        .then((data) => {
          element.cv = data;
          setNewArr(newArr);
        })
        .catch((error) => console.log(error));
    });
  };

  useEffect(() => {
    if (!admin) {
      let newArray = [...headCells];
      newArray.pop();
      setHeadItems(newArray);
      if (originalData.length) {
        setChangedElements(originalData);
      }
    } else {
      setHeadItems(headCells);
      if (originalData.length) {
        getCvFromDB(originalData, setChangedElements);
      }
    }
  }, [originalData]);

  const handleAction = (action) => {
    if (action === "denied") {
      setActive(1);
      setShowPopUp(true);
    } else {
      setActive(0);
      setShowPopUp(true);
    }
  };

  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === "desc"
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };
  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (name) => {
    setSelected(name);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0
      ? Math.max(0, (1 + page) * rowsPerPage - elementsChanged.length)
      : 0;

  const handleFile = () => {};

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={"medium"}
          >
            <TableHead>
              <TableRow sx={{ backgroundColor: "#DADBDF" }}>
                {headItems.map((headCell) => (
                  <TableCell
                    key={headCell.id}
                    align={headCell.numeric ? "right" : "left"}
                    padding={headCell.disablePadding ? "none" : "normal"}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={createSortHandler(headCell.id)}
                    >
                      {headCell.label}
                      {orderBy === headCell.id ? (
                        <Box component="span" sx={visuallyHidden}>
                          {order === "desc"
                            ? "sorted descending"
                            : "sorted ascending"}
                        </Box>
                      ) : null}
                    </TableSortLabel>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {stableSort(elementsChanged, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={() => handleClick(row.id)}
                      key={index}
                    >
                      <TableCell
                        sx={{ fontSize: "11px" }}
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        {row.user}
                      </TableCell>
                      <TableCell sx={{ fontSize: "11px" }}>
                        {row.email}
                      </TableCell>
                      <TableCell>
                        {row.declinedReason ? row.declinedReason : "N/A"}
                      </TableCell>

                      {row.status === 0 ? (
                        <TableCell
                          sx={{ backgroundColor: "#ABCAA9", fontSize: "11px" }}
                        >
                          {t("cvapproved")}
                        </TableCell>
                      ) : row.status === 1 ? (
                        <TableCell
                          sx={{ backgroundColor: "#F99797", fontSize: "11px" }}
                        >
                          {t("cvdenied")}
                        </TableCell>
                      ) : (
                        <TableCell
                          sx={{ backgroundColor: "#E9EBED", fontSize: "11px" }}
                        >
                          {t("cvpending")}
                        </TableCell>
                      )}
                      {row.consent === 0 ? (
                        <TableCell
                          sx={{ backgroundColor: "#ABCAA9", fontSize: "11px" }}
                        >
                          {t("cvapproved")}
                        </TableCell>
                      ) : row.consent === 1 ? (
                        <TableCell
                          sx={{ backgroundColor: "#F99797", fontSize: "11px" }}
                        >
                          {t("cvdenied")}
                        </TableCell>
                      ) : (
                        <TableCell
                          sx={{ backgroundColor: "#E9EBED", fontSize: "11px" }}
                        >
                          {t("cvpending")}
                        </TableCell>
                      )}
                      <TableCell sx={{ fontSize: "11px" }}>
                        {row.consentDate}
                      </TableCell>
                      <TableCell sx={{ fontSize: "11px" }}>
                        {row.createdDate}
                      </TableCell>
                      <TableCell
                        sx={{ fontSize: "11px" }}
                        onClick={handleFile(row.fileId)}
                      >
                        {row.cv ? row.cv.name : admin ? "Loading.." : "N/A"}
                      </TableCell>
                      {admin &&
                        (row.consent === 0 && row.status === 3 ? (
                          <TableCell>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "10px",
                                alignItems: "center",
                              }}
                            >
                              <Box
                                sx={{
                                  backgroundColor:
                                    active === "denied" && "#CBCBCB",
                                }}
                                onClick={() => handleAction("denied", row)}
                              >
                                <Button
                                  variant="contained"
                                  color="error"
                                  size="small"
                                  sx={{
                                    fontSize: "11px",
                                    opacity: 0.5,
                                    "&:hover": { opacity: 1 },
                                  }}
                                >
                                  {t("deny")}
                                </Button>
                              </Box>
                              <Box
                                sx={{
                                  backgroundColor:
                                    active === "approved" && "#CBCBCB",
                                }}
                                onClick={() => handleAction("approved", row)}
                              >
                                <Button
                                  variant="contained"
                                  color="success"
                                  size="small"
                                  sx={{
                                    opacity: 0.5,
                                    fontSize: "11px",

                                    "&:hover": { opacity: 1 },
                                  }}
                                >
                                  {t("approve")}
                                </Button>
                              </Box>
                              {row.status === 3 && (
                                <Box sx={{ display: "flex" }}>
                                  <a
                                    download={row.cv.name}
                                    href={`data:image/png;base64,${row.cv.cv}`}
                                  >
                                    <CardMedia
                                      sx={{
                                        width: "27px",
                                      }}
                                      title="Download CV"
                                      component="img"
                                      src={DownloadIcon}
                                    />
                                  </a>
                                </Box>
                              )}
                            </Box>
                          </TableCell>
                        ) : row.status === 0 ? (
                          <TableCell>
                            <Box sx={{ display: "flex" }}>
                              <a
                                download={row.cv.name}
                                href={`data:image/png;base64,${row.cv.cv}`}
                              >
                                <CardMedia
                                  sx={{
                                    width: "27px",
                                  }}
                                  title="Download CV"
                                  component="img"
                                  src={DownloadIcon}
                                />
                              </a>
                            </Box>
                          </TableCell>
                        ) : (
                          <TableCell>{t("timestampMessage")}</TableCell>
                        ))}
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          sx={{
            "& div > div, p ": {
              my: 0,
            },
          }}
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={elementsChanged.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>

      {showPopUp && (
        <PopUp
          removeElements={setSelected}
          handleChangedElements={setChangedElements}
          closePopUp={setShowPopUp}
          element={selected}
          active={active}
          setReloadDB={setReloadDB}
        />
      )}
    </Box>
  );
}
