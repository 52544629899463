import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Box, Avatar, Typography, CardMedia } from "@mui/material";
import TotalIcon from "../../assets/icons/estatistica.svg";
import ApprovedIcon from "../../assets/icons/aprovados.svg";
import DeniedIcon from "../../assets/icons/rejeitados.svg";
import PendingIcon from "../../assets/icons/pendentes.svg";

export default function MyStats({ user, myRanking }) {
  const { t } = useTranslation();
  return (
    <Box sx={{ width: "100%", p: "30px 40px" }}>
      <Typography
        sx={{ color: "#000", fontWeight: 700, fontFamily: "Montserrat" }}
      >
        {t("stats")}
      </Typography>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
          borderRadius: "15px",
          gap: "10px",
          p: { xs: "30px 0px", md: "30px 40px" },
        }}
      >
        <Box
          sx={{
            width: { xs: "45%", md: "20%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            backgroundColor: "#ffcd00",
            p: "10px",
            borderRadius: "15px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
          }}
        >
          <Box>
            <Avatar
              sx={{
                backgroundColor: "#ffcd00",
              }}
            >
              <CardMedia
                sx={{ width: "30px" }}
                component="img"
                src={TotalIcon}
              />
            </Avatar>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: "#000",
                fontFamily: "Montserrat",
              }}
            >
              {t("cvssubmitted")}
            </Typography>
            <Typography
              sx={{
                fontWeight: "800",
                fontFamily: "Montserrat",
                color: "#000",
              }}
            >
              {myRanking.approved + myRanking.denied + myRanking.pending || 0}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "45%", md: "20%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            backgroundColor: "#006400c4",
            p: "10px",
            borderRadius: "15px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
          }}
        >
          <Box>
            <Avatar
              sx={{
                backgroundColor: "#ABCAA9",
              }}
            >
              <CardMedia
                sx={{ width: "30px" }}
                component="img"
                src={ApprovedIcon}
              />
            </Avatar>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: "#000",
                fontFamily: "Montserrat",
              }}
            >
              {t("cvapproved")}
            </Typography>
            <Typography
              sx={{
                fontWeight: "800",
                fontFamily: "Montserrat",
                color: "#000",
              }}
            >
              {myRanking.approved || 0}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "45%", md: "20%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            backgroundColor: "#f80000ad",
            p: "10px",
            borderRadius: "15px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
          }}
        >
          <Box>
            <Avatar
              sx={{
                backgroundColor: "#F99797",
              }}
            >
              <CardMedia
                sx={{ width: "30px" }}
                component="img"
                src={DeniedIcon}
              />
            </Avatar>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: "#000",
                fontFamily: "Montserrat",
              }}
            >
              {t("cvdenied")}
            </Typography>
            <Typography
              sx={{
                fontWeight: "800",
                fontFamily: "Montserrat",
                color: "#000",
              }}
            >
              {myRanking.denied || 0}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            width: { xs: "45%", md: "20%" },
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            gap: "10px",
            backgroundColor: "#a4a2a2",

            p: "10px",
            borderRadius: "15px",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
          }}
        >
          <Box>
            <Avatar
              sx={{
                backgroundColor: "#CAC7C7",
              }}
            >
              <CardMedia
                sx={{ width: "30px" }}
                component="img"
                src={PendingIcon}
              />
            </Avatar>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{
                fontWeight: "500",
                color: "#000",
                fontFamily: "Montserrat",
              }}
            >
              {t("cvpending")}
            </Typography>
            <Typography
              sx={{
                fontWeight: "800",
                fontFamily: "Montserrat",
                color: "#000",
              }}
            >
              {myRanking.pending || 0}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
