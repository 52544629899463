import React from "react";
import SimpleNav from "./../components/core/SimpleNav";
import { Layout } from "./../components/Layout";
import Footer from "../components/core/Footer";
import { Box } from "@mui/material";
import { useNavigate } from "react-router";
import Banner from "../assets/images/banner_politicas.jpg";

const Qualidade = () => {
  return (
    <Layout>
      <SimpleNav />
      <Box sx={{ p: "20px" }}>
        <div class="modal-header">
          <img class="w-100" src={Banner} />
        </div>
        <div class="modal-body">
          <section class="job-offer-container">
            <h2 class="modal-title" id="modalTitle">
              Pol&#237;tica da Qualidade e Concilia&#231;&#227;o entre a Vida
              Profissional, Familiar e Pessoal
            </h2>
            <div class="container-fluid">
              <div class="row">
                <div class="col">
                  <p>
                    A agap2IT é uma organização europeia na área dos Sistemas de
                    Informação, Ciência e Tecnologia, empenhada na inovação,
                    pelo que, reconhece que a Qualidade e a satisfação dos seus
                    colaboradores, clientes e parceiros são um fator primordial
                    para o desenvolvimento estratégico e sustentado da empresa.
                    Desta forma, pretendemos uma melhoria continua sobre os
                    processos e serviços prestados, através de altos padrões de
                    qualidade, de inovação, de respeito e de compromisso com as
                    pessoas.
                  </p>
                  <p>
                    Para atingir os seus objetivos, a agap2IT baseia a sua
                    Política nos seguintes princípios:
                  </p>
                  <ul>
                    <li>
                      Garantir a transparência da informação e o respeito mútuo
                      das relações que estabelece com as partes interessadas;
                    </li>
                    <li>
                      Identificar e satisfazer as necessidades dos Clientes
                      prestando um serviço de excelência e promover a criação de
                      valor junto dos mesmos;
                    </li>
                    <li>
                      Garantir um alinhamento e foco permanente com os valores
                      da empresa;
                    </li>
                    <li>
                      Melhorar continuamente os sistemas de gestão, a qualidade
                      dos serviços e o funcionamento dos processos, de modo a
                      promover um aumento da eficácia, um aumento da
                      competitividade
                      <br />e um aumento da satisfação e fidelização dos
                      clientes;
                    </li>
                    <li>
                      Promover a competência, a comunicação, a motivação e o
                      trabalho em equipa, através da sensibilização, formação e
                      responsabilização de todos os colaboradores;
                    </li>
                    <li>
                      Garantir a existência de condições efetivas para uma
                      mudança cultural de conciliação entre a vida profissional,
                      familiar e pessoal, e assunção de um compromisso coletivo
                      a longo prazo;
                    </li>
                    <li>
                      Manter um sistema de gestão da qualidade, segundo os
                      requisitos da norma ISO 9001, em cumprimento com a
                      legislação aplicável e requisitos contratuais;
                    </li>
                    <li>
                      Manter um sistema de gestão da conciliação entre a vida
                      profissional, familiar e pessoal, segundo os requisitos da
                      norma NP 4552 e tendo em conta os três domínios da
                      conciliação:
                      <br />
                      boas práticas laborais; apoio profissional e
                      desenvolvimento pessoal; serviços e benefícios, em
                      <br />
                      cumprimento com a legislação e requisitos aplicáveis.
                    </li>
                  </ul>
                  <p>
                    Deste modo, a implementação e manutenção de um Sistema de
                    Gestão da Qualidade e de Gestão da Conciliação entre a Vida
                    Profissional, Familiar e Pessoal constituem ferramentas para
                    a evolução da atividade da empresa, de modo a concretizar os
                    objetivos e metas definidos anualmente.
                  </p>
                  <p> </p>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Box>
      <Footer />
    </Layout>
  );
};

export default Qualidade;
