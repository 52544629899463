import React, { useState, useRef } from "react";
import { Box, TextField, Button, Typography, CardMedia } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Checkbox from "@mui/material/Checkbox";
import SubmitIcon from "../../assets/icons/submeter.svg";
import AddIcon from "../../assets/icons/adicionar.svg";
import CloseIcon from "@mui/icons-material/Close";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function SendCV({ loadingCV }) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const user = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [candidate, setCandidate] = useState("");
  const [candidateName, setCandidateName] = useState("");
  const [fileToPost, setFileToPost] = useState({});
  const [isTrue, setIsTrue] = useState(false);
  const [inform, setInform] = useState(false);
  const isTrueRef = useRef();
  const informRef = useRef();
  const url = process.env.REACT_APP_MAIN_URL ?? process.env.PUBLIC_URL;
  let condition =
    isTrue &&
    inform &&
    candidate.length &&
    candidateName.length &&
    fileToPost.name;
  const handleFile = (e) => {
    const file = e.target.files[0];
    let typeOfFile = file.name.split(".").pop();
    let condition =
      typeOfFile === "pdf" || typeOfFile === "doc" || typeOfFile === "docx";
    if (condition) {
      setFileToPost(file);
    } else {
      enqueueSnackbar(`${t("toastDocs")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    }
  };

  const handleSubmit = (e) => {
    //const alphaNumRegex = /^[a-zA-Z]+(?:\s[a-zA-Z]+)*$/;
    const alphaNumRegex = /^[A-Za-záàâãéèêíïóôõöúçñÁÀÂÃÉÈÍÏÓÔÕÖÚÇÑ ]+$/;
    var regexEmail = /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/;
    if (!alphaNumRegex.test(candidateName.trim())) {
      enqueueSnackbar(`${t("toastErrorCVNameCharacters")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
      return;
    }
    if (!regexEmail.test(candidate)) {
      enqueueSnackbar(`${t("toastErrorCVEmail")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
      return;
    }

    setLoading(true);
    loadingCV(true);
    const information = new FormData();
    information.append("CV", fileToPost);
    information.append("Email", candidate);
    information.append("Name", candidateName);
    information.append("TrueInformation", isTrue);
    information.append("Information", inform);
    const token = user.token;
    const bearer = "Bearer " + token;
    fetch(url + "api/CvInformation", {
      method: "POST",
      headers: {
        Accept: "*/*",
        Authorization: bearer,
      },
      body: information,
    })
      .then((resp) => {
        if (resp.ok) {
          document.getElementById("formCv").reset();
          setFileToPost({});
          enqueueSnackbar(`CV ${t("cvsubmitted")}`, {
            variant: "success",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
          });
          setIsTrue(false);
          setInform(false);
          isTrueRef.current.children[0].checked = false;
          informRef.current.children[0].checked = false;
        } else {
          enqueueSnackbar(`${t("toastError")}`, {
            variant: "error",
            anchorOrigin: {
              horizontal: "center",
              vertical: "top",
            },
          });
        }
      })
      .catch(() => {
        enqueueSnackbar(`${t("toastError")}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      })
      .finally(() => {
        loadingCV(false);
        setLoading(false);
      });
  };

  return (
    <Box sx={{ px: "40px", width: "100%" }}>
      <Box
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          position: "relative",
          ml: 0,
          py: "40px",
          borderRadius: "15px",
          backgroundColor: "white",
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
        }}
      >
        <Box
          sx={{
            pl: "40px",
            pb: "40px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography
            variant="h3"
            sx={{ fontSize: "1rem", fontWeight: 700, fontFamily: "Montserrat" }}
          >
            {t("sendcvtitle")}
          </Typography>
        </Box>
        <Box sx={{ width: "100%", px: "40px" }}>
          <form
            id="formCv"
            style={{ width: "100%" }}
            encType="multipart/form-data"
            name="CV"
          >
            <Box
              sx={{
                borderRadius: "15px",
                color: "white",
                flexDirection: { xs: "column", md: "row" },
                display: "flex",
                alignItems: "center",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "20px",
                }}
              >
                <TextField
                  sx={{
                    color: "#fff",
                    width: "100%",
                    backgroundColor: "#fff",
                    p: "2px",
                    borderRadius: "5px",
                  }}
                  required
                  value={candidate.name}
                  id="name"
                  label={t("name")}
                  type="text"
                  variant="standard"
                  onChange={(event) => setCandidateName(event.target.value)}
                />
                <TextField
                  sx={{
                    color: "#fff",
                    width: "100%",
                    backgroundColor: "#fff",
                    p: "2px",
                    borderRadius: "5px",
                  }}
                  autoComplete="email"
                  required
                  value={candidate.email}
                  id="email"
                  label={t("candidateemail")}
                  type="email"
                  variant="standard"
                  onChange={(event) => setCandidate(event.target.value)}
                />
              </Box>
              {fileToPost.name && (
                <Box sx={{ display: "flex", px: "20px" }}>
                  <CloseIcon
                    sx={{
                      color: "red",
                      width: "15px",
                      pb: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => setFileToPost({})}
                  />
                  <Typography
                    sx={{
                      color: "black",
                    }}
                  >
                    {fileToPost.name}
                  </Typography>
                </Box>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  border: "2px solid transparent",
                  borderRadius: "5px",
                  backgroundColor: "#808080",
                  opacity: fileToPost.name ? 0.5 : 1,
                  transition: "0.8s",
                  color: "white",
                  px: "15px",
                  py: "5px",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: fileToPost.name ? "#808080" : "white",
                    color: fileToPost.name ? "white" : "#808080",
                    borderColor: fileToPost.name ? "transparent" : "#808080",
                  },
                }}
              >
                <label
                  style={{
                    display: "flex",
                    width: "max-content",
                    fontFamily: "Montserrat",
                    alignItems: "center",
                    cursor: "pointer",
                  }}
                  htmlFor="file-upload"
                  className="custom-file-upload"
                >
                  <CardMedia
                    sx={{ width: "12px", cursor: "pointer", mr: "10px" }}
                    component="img"
                    src={AddIcon}
                  />

                  <p style={{ margin: 0, cursor: "pointer" }}>{t("addcv")}</p>
                </label>
                <input
                  style={{ display: "none", fontFamily: "Montserrat" }}
                  id="file-upload"
                  type="file"
                  accept=".pdf"
                  onChange={handleFile}
                  disabled={fileToPost.name ? true : false}
                />
              </Box>

              <Box>
                <Button
                  //variant="outlined"
                  sx={{
                    width: "100%",
                    transition: "0.8s",
                    border: "2px solid transparent",
                    backgroundColor: "gray",
                    minWidth: 200,
                    opacity: !condition ? 0.5 : 1,
                    display: "flex",
                    gap: "10px",
                    "& p": { color: "white" },
                    borderRadius: "5px",
                    "&:hover": {
                      border: "2px solid gray",
                      transition: "0.8s",
                      backgroundColor: "white",
                      "& p": { color: "gray" },
                      "& svg": { color: "white !important" },
                    },
                  }}
                  disabled={!condition}
                  onClick={handleSubmit}
                >
                  <CardMedia
                    sx={{ width: "20px" }}
                    component="img"
                    src={SubmitIcon}
                  />

                  <Typography sx={{ color: "gray", fontFamily: "Montserrat" }}>
                    {t("register")}
                  </Typography>
                </Button>
              </Box>
            </Box>
          </form>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "baseline",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              pl: "40px",
            }}
          >
            <Checkbox
              ref={isTrueRef}
              label="policy"
              value={isTrue}
              checked={isTrue}
              onChange={() => setIsTrue(!isTrue)}
            />
            <Typography sx={{ fontSize: "0.8rem" }}>
              {t("cvcheckbox")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              pl: "40px",
            }}
          >
            <Checkbox
              ref={informRef}
              label="policy"
              value={inform}
              checked={inform}
              onChange={() => setInform(!inform)}
            />
            <Typography sx={{ fontSize: "0.8rem" }}>
              {t("cvcheckbox2")}
            </Typography>
          </Box>
        </Box>

        {loading && (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              backgroundColor: "rgba(255,255,255, 0.5)",
              position: "absolute",
              borderRadius: "15px",
              top: 0,
              left: 0,
            }}
          >
            <CircularProgress
              size={24}
              sx={{
                color: "blue",
                position: "absolute",
                top: "50%",
                left: "50%",
                marginTop: "-12px",
                marginLeft: "-12px",
              }}
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
