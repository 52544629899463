import React from "react";
import Footer from "../components/core/Footer";
import SimpleNav from "../components/core/SimpleNav";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableComponent from "../components/core/TableComponent";

const Politicas = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <SimpleNav />
      <Box sx={{ p: "20px" }}>
        <Typography variant="h1" sx={{ textAlign: "center", color: "#ffcd00" }}>
          {t("privacyPolicyTitle")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "100px",
            mt: "100px",
          }}
        >
          <Box>
            <Typography variant="h4">{t("guidelines")}</Typography>
            <Typography>{t("guidelinesText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("dataController")}</Typography>
            <Typography>
              {t("dataControllerText")}
              <Box sx={{ mt: "10px" }}>
                <Typography>{t("contacts")}</Typography>

                <ul>
                  <li>{t("postalAddress")}</li>
                  <li>{t("phoneContact")}</li>
                  <li>{t("email")}</li>
                </ul>
              </Box>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("legalNotice")}</Typography>
            <Typography>
              {t("legalNoticeText")}
              <ul>
                <li>{t("legalNoticeLi1")}</li>
                <li>{t("legalNoticeLi2")}</li>
                <li>{t("legalNoticeLi3")}</li>
              </ul>
              {t("legalNoticeText2")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("appScope")}</Typography>
            <Typography>{t("appScopeText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("gpd")}</Typography>
            <Typography>
              {t("gpdText")}
              <Typography>
                {t("contacts")}
                <ul>
                  <li>{t("gpdContact")}</li>
                </ul>
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("personalDataTitle")}</Typography>
            <Typography variant="h4">{t("personalDataSubTitle")}</Typography>
            <Typography>{t("personalDataText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("dataCollect")}</Typography>
            <Typography>{t("dataCollectText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("whatData")}</Typography>
            <Typography>
              {t("whatDataText")}
              <Box sx={{ display: "flex", my: "10px" }}>
                <TableComponent
                  headCells={[t("dataCategory"), t("collectedInfo")]}
                  rows={[
                    {
                      title: t("whatDataTitle1"),
                      description: t("whatDataText1"),
                    },
                    {
                      title: t("whatDataTitle2"),
                      description: t("whatDataText2"),
                    },
                    {
                      title: t("whatDataTitle3"),
                      description: t("whatDataText3"),
                    },
                  ]}
                />
              </Box>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("purpose")}</Typography>
            <Box sx={{ display: "flex", my: "10px" }}>
              <TableComponent
                headCells={[t("dataCategory"), t("collectedInfo")]}
                rows={[
                  {
                    title: t("purposeTitle1"),
                    description: t("purposeText1"),
                  },
                  {
                    title: t("purposeTitle2"),
                    description: t("purposeText2"),
                  },
                  {
                    title: t("purposeTitle3"),
                    description: t("purposeText3"),
                  },
                ]}
              />
            </Box>
          </Box>
          <Box>
            <Typography variant="h4">{t("legalPurpose")}</Typography>
            <Typography>
              {t("legalPurposeText")}

              <ul>
                <li>{t("legalPurposeLi1")}</li>
                <li>{t("legalPurposeLi2")}</li>
                <li>{t("legalPurposeLi3")}</li>
                <li> {t("legalPurposeLi4")}</li>
                <li> {t("legalPurposeLi5")}</li>
                <li>{t("legalPurposeLi6")}</li>
              </ul>
            </Typography>
            <Typography>
              {t("legalPurposeText2")}

              <ol>
                <li>{t("legalPurposeOl1")}</li>
                <li>{t("legalPurposeOl2")}</li>
                <li>{t("legalPurposeOl3")}</li>
                <li>{t("legalPurposeOl4")}</li>
              </ol>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("toWhom")}</Typography>
            <Typography>
              {t("toWhomText")}
              <ul>
                <li>{t("whomOl1")}</li>
                <li>{t("whomOl2")}</li>
                <li>{t("whomOl3")}</li>
                <li>{t("whomOl4")}</li>
                <li>{t("whomOl5")}</li>
                <li>{t("whomOl6")}</li>
              </ul>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("howLongTitle")}</Typography>
            <Typography>{t("howLongTitleText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("yourRights")}</Typography>
            <Typography variant="h4">{t("subjectRights")}</Typography>
            <Typography>{t("subjectRightsText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("accessRight")}</Typography>
            <Typography>
              {t("accessRightText")}
              <ul>
                <li>{t("accessOl1")}</li>
                <li>{t("accessOl2")}</li>
                <li>{t("accessOl3")}</li>
                <li>{t("accessOl4")}</li>
                <li>{t("accessOl5")}</li>
                <li>{t("accessOl6")}</li>
                <li>{t("accessOl7")}</li>
              </ul>
              {t("accessRightText2")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("rectification")}</Typography>
            <Typography>{t("rectificationText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("forgotten")}</Typography>
            <Typography>{t("forgottenText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("limitation")}</Typography>
            <Typography>
              {t("limitationText")}
              <ul>
                <li>{t("limitationOl1")}</li>
                <li>{t("limitationOl2")} </li>
                <li>{t("limitationOl3")} </li>
                <li>{t("limitationOl4")} </li>
              </ul>
              {t("limitationText2")}
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("notification")}</Typography>
            <Typography>{t("notificationText")} </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("portability")}</Typography>
            <Typography>
              {t("portabilityText")}

              <ul>
                <li> {t("portabilityOl1")}</li>
                <li> {t("portabilityOl2")}</li>
                <li> {t("portabilityOl3")}</li>
              </ul>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("rights")}</Typography>
            <Typography>{t("rightsText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("reponsabilities")} </Typography>
            <Typography variant="h4">{t("reponsabilitiesTitle")}</Typography>
            <Typography>{t("reponsabilitiesText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("globalProjects")}</Typography>
            <Typography>
              {t("globalProjectsText")}
              <ul>
                <li>{t("globalProjectsLi1")}</li>
                <li>{t("globalProjectsLi2")}</li>
                <li>{t("globalProjectsLi3")}</li>
                <li>{t("globalProjectsLi4")}</li>
                <li>{t("globalProjectsLi5")}</li>
              </ul>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h4"> {t("dataViolation")}</Typography>
            <Typography>{t("dataViolationText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("cookies")}</Typography>
            <Typography variant="h4">{t("whatCookies")}</Typography>
            <Typography>{t("whatCookiesText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("whyCookies")}</Typography>
            <Typography>{t("whyCookiesText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("techCookies")} </Typography>
            <Typography>{t("techCookiesText")} </Typography>
            <TableComponent
              headCells={[
                t("cookie"),
                t("cookieDesignation"),
                t("cookieFinallity"),
                t("cookieNature"),
                t("cookieDuration"),
              ]}
              rows={[
                {
                  title: t("cookie1Title"),
                  description: t("cookie1Description"),
                  finalidade: t("cookie1Finallity"),
                  nature: t("cookie1Nature"),
                  duration: t("cookie1Duration"),
                },
                {
                  title: t("cookie2Title"),
                  description: t("cookie2Description"),
                  finalidade: t("cookie2Finallity"),
                  nature: t("cookie2Nature"),
                  duration: t("cookie2Duration"),
                },
                {
                  title: t("cookie3Title"),
                  description: t("cookie3Description"),
                  finalidade: t("cookie3Finallity"),
                  nature: t("cookie3Nature"),
                  duration: t("cookie3Duration"),
                },
              ]}
            />
          </Box>
          <Box>
            <Typography variant="h4">{t("performanceCookies")} </Typography>
            <Typography>{t("performanceCookiesText")} </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("funcionalityCookies")} </Typography>
            <Typography>{t("funcionalityCookiesText")} </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("revogationConsent")}</Typography>
            <Typography>{t("revogationConsentText")} </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("complaint")}</Typography>
            <Typography>{t("complaintText")} </Typography>
            <Typography>{t("complaintAuthority")}</Typography>
            <Box sx={{ pl: "20px" }}>
              <Typography>{t("compaintName")}</Typography>
              <Typography>{t("complaintAddress")}</Typography>
              <Typography>{t("complaintPostal")}</Typography>
              <Typography>{t("complaintPhone")}</Typography>
              <Typography>{t("complaintSite")}</Typography>
            </Box>
          </Box>
          <Box>
            <Typography variant="h4">{t("policyChanges")} </Typography>
            <Typography>{t("policyChangesText")} </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("applicableLaw")} </Typography>
            <Typography>{t("applicableLawText")} </Typography>
          </Box>
          <Box>
            <Typography variant="h4">{t("policyContact")} </Typography>
            <Typography>{t("policyContactText")} </Typography>
          </Box>
        </Box>
      </Box>

      <Footer relative={"relative"} />
    </Box>
  );
};

export default Politicas;
