import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import {
  Box,
  Typography,
  Button,
  TextField,
  CircularProgress,
} from "@mui/material";
import ShowIcon from "@mui/icons-material/VisibilityOutlined";
import HideIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useTranslation } from "react-i18next";

export default function Admin() {
  const { t } = useTranslation();
  const userFromRedux = useSelector((state) => state.user.value);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [showRegisterPass, setShowRegisterPass] = useState(false);
  const [showRegisterConfirmPass, setShowRegisterConfirmPass] = useState(false);
  const [userRegister, setUserRegister] = useState("");
  const [userRegisterName, setUserRegisterName] = useState("");
  const [userRegisterPassword, setUserRegisterPassword] = useState("");
  const [userRegisterConfirmPassword, setUserRegisterConfirmPassword] =
    useState("");
  const [passLength, setPassLength] = useState(false);
  const [alphaNum, setAlphaNum] = useState(false);
  const [passUpper, setPassUpper] = useState(false);
  const [passLower, setPassLower] = useState(false);
  const [passNumber, setPassNumber] = useState(false);
  const adminCondition =
    userRegister.length &&
    userRegisterName.length &&
    userRegisterPassword.length &&
    userRegisterConfirmPassword.length;

  const url = process.env.REACT_APP_MAIN_URL ?? process.env.PUBLIC_URL;

  const handleRegister = async () => {
    const passWordPAttern =
      /(?=^.{14,}$)(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*/;

    const userToSend = {
      email: userRegister,
      password: userRegisterPassword,
      name: userRegisterName,
      secondPassword: userRegisterConfirmPassword,
      acceptTerms: true,
      acceptedRules: true,
      role: "admin",
    };

    if (userRegisterPassword !== userRegisterConfirmPassword) {
      enqueueSnackbar(`${t("passNoMatch")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
      return;
    }
    if (!passWordPAttern.test(userRegisterPassword)) {
      enqueueSnackbar(`${t("passNoMatchPrerequesites")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
      return;
    }
    setLoading(true);
    try {
      const token = userFromRedux.token;
      const bearer = "Bearer " + token;
      const response = await fetch(url + "api/Account/userRegistration", {
        method: "POST",
        headers: {
          Authorization: bearer,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userToSend),
      });
      if (response.ok) {
        enqueueSnackbar(`${t("adminAdded")}`, {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
        document.getElementById("adminForm").reset();
        setUserRegister("");
        setUserRegisterName("");
        setUserRegisterPassword("");
        setUserRegisterConfirmPassword("");
      } else {
        enqueueSnackbar(`${t("toastError")}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar(`${t("toastError")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    } finally {
      setLoading(false);
    }
  };
  let confirmPassSteps = () => {
    const passLengthRegex = /(^.{14,}$)/;
    const passNumberRegex = /[0-9]/;
    const alphaNumRegex = /[^A-Za-z0-9]/;
    const upperPassRegex = /[A-Z]/;
    const lowerPassRegex = /[a-z]/;
    if (passLengthRegex.test(userRegisterPassword)) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
    if (passNumberRegex.test(userRegisterPassword)) {
      setPassNumber(true);
    } else {
      setPassNumber(false);
    }
    if (alphaNumRegex.test(userRegisterPassword)) {
      setAlphaNum(true);
    } else {
      setAlphaNum(false);
    }
    if (lowerPassRegex.test(userRegisterPassword)) {
      setPassLower(true);
    } else {
      setPassLower(false);
    }
    if (upperPassRegex.test(userRegisterPassword)) {
      setPassUpper(true);
    } else {
      setPassUpper(false);
    }
  };

  useEffect(() => {
    confirmPassSteps();
  }, [userRegisterPassword]);
  return (
    <Box
      sx={{
        p: "40px",
        m: "0 auto",
        mb: "100px",
      }}
    >
      <form id="adminForm" action="/authentication/register" method="post">
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "space-between",
            gap: "20px",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              width: { xs: "100%", sm: "45%" },
              display: "flex",
              alignItems: "end",
            }}
          >
            <TextField
              required={true}
              value={userRegisterName}
              autoComplete="Name"
              sx={{ width: "100%" }}
              id="registerName"
              label={t("name")}
              type="text"
              variant="standard"
              onChange={(e) => setUserRegisterName(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", sm: "45%" },
              display: "flex",
              alignItems: "end",
            }}
          >
            <TextField
              required={true}
              value={userRegister}
              autoComplete="email"
              sx={{ width: "100%" }}
              id="registerEmail"
              label="Email"
              type="email"
              variant="standard"
              onChange={(e) => setUserRegister(e.target.value)}
            />
          </Box>
          <Box
            sx={{
              width: { xs: "100%", sm: "45%" },
              display: "flex",
              alignItems: "end",
              position: "relative",
            }}
          >
            <TextField
              required={true}
              value={userRegisterPassword}
              autoComplete="new-password"
              sx={{ width: "100%" }}
              id="registerPassword"
              label="Password"
              variant="standard"
              type={showRegisterPass ? "text" : "password"}
              onChange={(e) => setUserRegisterPassword(e.target.value)}
            />
            {showRegisterPass ? (
              <ShowIcon
                onClick={() => setShowRegisterPass(!showRegisterPass)}
                sx={{ position: "absolute", right: 0 }}
              />
            ) : (
              <HideIcon
                onClick={() => setShowRegisterPass(!showRegisterPass)}
                sx={{ position: "absolute", right: 0 }}
              />
            )}
          </Box>
          <Box
            sx={{
              width: { xs: "100%", sm: "45%" },
              display: "flex",
              alignItems: "end",
              position: "relative",
            }}
          >
            <TextField
              required={true}
              value={userRegisterConfirmPassword}
              autoComplete="new-password"
              sx={{ width: "100%" }}
              id="registerConfirmPassword"
              label={t("newPassConfirm")}
              type={showRegisterConfirmPass ? "text" : "password"}
              variant="standard"
              onChange={(e) => setUserRegisterConfirmPassword(e.target.value)}
            />
            {showRegisterConfirmPass ? (
              <ShowIcon
                onClick={() =>
                  setShowRegisterConfirmPass(!showRegisterConfirmPass)
                }
                sx={{ position: "absolute", right: 0 }}
              />
            ) : (
              <HideIcon
                onClick={() =>
                  setShowRegisterConfirmPass(!showRegisterConfirmPass)
                }
                sx={{ position: "absolute", right: 0 }}
              />
            )}
          </Box>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Typography
              variant="caption"
              sx={{
                fontSize: "10px",
                color: "gray",
              }}
            >
              {t("passConditions")}
              <ul>
                <li style={{ color: passLength ? "green" : "red" }}>
                  {t("passCharacters")}
                </li>
                <li style={{ color: alphaNum ? "green" : "red" }}>
                  {t("passAlfanumeric")}
                </li>
                <li style={{ color: passNumber ? "green" : "red" }}>
                  {t("passDigit")}{" "}
                </li>
                <li style={{ color: passUpper ? "green" : "red" }}>
                  {t("passUppercase")}{" "}
                </li>
                <li style={{ color: passLower ? "green" : "red" }}>
                  {t("passLowercase")}{" "}
                </li>
              </ul>
            </Typography>
          </Box>

          <Box
            sx={{
              position: "relative",
              display: "inline-block",
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Button
              variant="contained"
              disabled={!adminCondition}
              sx={{
                transition: "0.8s",
                border: "2px solid black",
                minWidth: 200,

                backgroundColor: "black",
                borderRadius: "5px",
                "&:hover": {
                  backgroundColor: "transparent",
                  borderColor: "black",
                  transition: "0.8s",
                  color: "FFD526",

                  "& p": { color: "gray !important" },
                },
              }}
              onClick={handleRegister}
            >
              <Typography
                sx={{
                  color: "white",
                  fontWeight: "900",
                  fontFamily: "Cags_Hetonal",
                  textTransform: "lowercase",
                  "&:hover": {
                    color: "black",
                  },
                }}
              >
                {t("register")}
              </Typography>
            </Button>
            {loading && (
              <CircularProgress
                size={24}
                sx={{
                  color: "blue",
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            )}
          </Box>
        </Box>
      </form>
    </Box>
  );
}
