import React from "react";
import { Box, Link, Typography } from "@mui/material";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import YouTubeIcon from "@mui/icons-material/YouTube";
import CircleIcon from "@mui/icons-material/Circle";
import CardMedia from "@mui/material/CardMedia";
import footerLogo from "../../assets/icons/footer_logo.svg";
import logo1 from "../../assets/images/footer/logo1.svg";
import logo2 from "../../assets/images/footer/logo2.svg";
import logo3 from "../../assets/images/footer/logo3.svg";
import logo4 from "../../assets/images/footer/logo4.svg";
import pdf1 from "../../assets/pdfs/1.pdf";
import pdf2 from "../../assets/pdfs/2.pdf";
import { useTranslation } from "react-i18next";

const Footer = ({ fixed, relative, nomargintop }) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        backgroundColor: "#000",
        borderTop: "0.5rem solid #ffcd00",
        top: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 4,
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100px",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: { xs: "center", md: "space-between" },
          alignItems: "center",
          px: "10px",
        }}
      >
        {/* -------------------------------------------------------TopRow------------------------- */}
        <Box
          sx={{
            display: "flex",
            minWidth: "30%",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <CardMedia sx={{ width: "120px" }} component="img" src={footerLogo} />
          <Box sx={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <CircleIcon sx={{ fontSize: "10px", color: "white" }} />{" "}
            <Typography sx={{ display: "flex", color: "white" }}>
              {t("footerLogoText")}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            alignItems: "center",
            gap: "10px",
          }}
        >
          <CardMedia sx={{ height: "26px" }} component="img" src={logo1} />
          <CardMedia sx={{ height: "19px" }} component="img" src={logo2} />
          <CardMedia sx={{ height: "36px" }} component="img" src={logo3} />
          <CardMedia sx={{ height: "60px" }} component="img" src={logo4} />
        </Box>
      </Box>
      {/* --------------------------------------MiddleRow ---------------------- */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          px: { xs: "20px", md: "none" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Link
            href={pdf1}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <Typography
              sx={{ fontSize: { xs: "12px", md: "14px" }, color: "white" }}
            >
              {t("qualityCertificade")}
            </Typography>
          </Link>
          <CircleIcon sx={{ fontSize: "10px", mx: "10px", color: "white" }} />
          <Link
            href={pdf2}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <Typography
              sx={{ fontSize: { xs: "12px", md: "14px" }, color: "white" }}
            >
              {t("investigationPolitics")}
            </Typography>
          </Link>
          <CircleIcon sx={{ fontSize: "10px", mx: "10px", color: "white" }} />
          <Link
            href={"/politicas-de-privacidade"}
            target="_blank"
            underline="none"
            rel="noopener noreferrer"
          >
            <Typography
              sx={{ fontSize: { xs: "12px", md: "14px" }, color: "white" }}
            >
              {t("privacyPolitics")}
            </Typography>
          </Link>
          <CircleIcon sx={{ fontSize: "10px", mx: "10px", color: "white" }} />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            alignItems: "center",
            alignSelf: "center",
            justifyContent: "center",
          }}
        >
          <Link
            href={"/politicas-de-qualidade"}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <Typography
              sx={{ fontSize: { xs: "12px", md: "14px" }, color: "white" }}
            >
              {t("qualityPolitics")}
            </Typography>
          </Link>
          <CircleIcon sx={{ fontSize: "10px", mx: "10px", color: "white" }} />
          <Link
            href={"/regulamento-de-comunicacao-de-irregularidades"}
            target="_blank"
            rel="noopener noreferrer"
            underline="none"
          >
            <Typography
              sx={{ fontSize: { xs: "12px", md: "14px" }, color: "white" }}
            >
              {t("regulation")}
              Regulamento de Comunicação de Irregularidades
            </Typography>
          </Link>
        </Box>
      </Box>
      {/*--------------------------------------------------------------------------- Social Media */}
      <Box
        sx={{
          display: "flex",
          alignSelf: "self-end",
          fontSize: "30px",
          gap: "20px",
          py: "10px",
          color: "white",
          pr: "20px",
        }}
      >
        <a
          title="Facebook"
          href="https://www.facebook.com/agap2.pt"
          style={{ color: "white" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FacebookOutlinedIcon fontSize="inherit" color="inherit" />
        </a>
        <a
          title="LinkedIn"
          href="https://www.linkedin.com/company/agap2it-portugal"
          style={{ color: "white" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <LinkedInIcon fontSize="inherit" color="inherit" />
        </a>
        <a
          title="Instagram"
          href="https://www.instagram.com/agap2IT/"
          style={{ color: "white" }}
          target="_blank"
          rel="noopener noreferrer"
        >
          <InstagramIcon fontSize="inherit" color="inherit" />
        </a>
        <a
          title="Youtube"
          href="https://www.youtube.com/channel/UC-BYTQ8_S6UWIHFBU-L2RDw"
          style={{ color: "white" }}
          rel="noopener noreferrer"
          target="_blank"
        >
          <YouTubeIcon fontSize="inherit" color="inherit" />
        </a>
      </Box>
    </Box>
  );
};

export default Footer;
