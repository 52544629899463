import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Table from "@mui/material/Table";
import {
  Avatar,
  Box,
  Typography,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import star from "./../../assets/icons/ranking.svg";
import { useTranslation } from "react-i18next";

export default function BasicTable({ boardList, arrLength }) {
  const { t } = useTranslation();
  const [listToShow, setListToShow] = useState([]);
  const userFromRedux = useSelector((state) => state.user.value);
  const admin = userFromRedux.admin;
  const user = userFromRedux.email;

  useEffect(() => {
    let sortedArray = [...boardList].sort((a, b) => {
      if (b.accepted === a.accepted) {
        return b.pending - a.pending;
      } else {
        return b.accepted - a.accepted;
      }
    });
    sortedArray.length = arrLength;
    setListToShow(sortedArray);
  }, [arrLength, boardList]);

  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>{t("user")}</TableCell>
            <TableCell align="right">{t("cvssubmitted")}</TableCell>
            <TableCell align="right">{t("cvapproved")}</TableCell>
            <TableCell align="right">{t("cvdenied")}</TableCell>
            <TableCell align="right">{t("cvpending")}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {listToShow.map((row, index) => (
            <TableRow
              key={index}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              {index === 0 ? (
                <TableCell component="th" scope="row">
                  <Box
                    sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  >
                    <Typography sx={{ fontFamily: "Montserrat" }}>
                      {index + 1}
                    </Typography>
                    <Avatar
                      sx={{ backgroundColor: "#ffcd00", p: "5px" }}
                      alt="first"
                      src={star}
                    />
                    <Typography sx={{ fontFamily: "Montserrat" }}>
                      {row.user}
                    </Typography>
                  </Box>
                </TableCell>
              ) : index === 1 ? (
                <TableCell component="th" scope="row">
                  <Box
                    sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  >
                    <Typography sx={{ fontFamily: "Montserrat" }}>
                      {index + 1}
                    </Typography>

                    <Avatar
                      sx={{ backgroundColor: "#ffcd00", p: "5px" }}
                      alt="first"
                      src={star}
                    />
                    <Typography sx={{ fontFamily: "Montserrat" }}>
                      {row.user}
                    </Typography>
                  </Box>
                </TableCell>
              ) : index === 2 ? (
                <TableCell component="th" scope="row">
                  <Box
                    sx={{ display: "flex", gap: "5px", alignItems: "center" }}
                  >
                    <Typography sx={{ fontFamily: "Montserrat" }}>
                      {index}
                    </Typography>
                    <Avatar
                      sx={{ backgroundColor: "#ffcd00", p: "5px" }}
                      alt="first"
                      src={star}
                    />
                    <Typography sx={{ fontFamily: "Montserrat" }}>
                      {row.user}
                    </Typography>
                  </Box>
                </TableCell>
              ) : (
                <TableCell component="th" scope="row">
                  <Typography sx={{ fontFamily: "Montserrat" }}>
                    {row.name}
                  </Typography>
                </TableCell>
              )}

              <TableCell align="right">
                {row.accepted + row.declined + row.pending}
              </TableCell>
              <TableCell align="right">{row.accepted}</TableCell>
              <TableCell align="right">{row.declined}</TableCell>
              <TableCell align="right">{row.pending}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
