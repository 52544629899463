import React from "react";
import { Box } from "@mui/material";
import image from "./../assets/images/agap2.png";

export const Layout = (props) => {
  return (
    <Box
      sx={{
        backgroundColor: "#f1f1f1",
        backgroundImage: `url(${image})`,
        backgroundSize: "cover",
        height: "100%",
        position: "relative",
      }}
    >
      {props.children}
    </Box>
  );
};
