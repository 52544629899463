import React from "react";
import Footer from "../components/core/Footer";
import SimpleNav from "../components/core/SimpleNav";
import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

const Agap2work = () => {
  const { t } = useTranslation();

  return (
    <Box>
      <SimpleNav />
      <Box
        sx={{
          p: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "40px",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            textAlign: "center",
            color: "#ffcd00",
            fontSize: { xs: "2rem", sm: "4rem" },
          }}
        >
          {t("newRegulationTitle")}
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "25px" }}>
          <Box>
            <Typography variant="h5">{t("newRegulationObjective")}</Typography>
            <Typography>{t("newRegulationObjectiveText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h5">
              {t("newRegulationParticipants")}
            </Typography>
            <Typography>{t("newRegulationParticipantesText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h5">{t("newRegulationNonPart")}</Typography>
            <Typography>{t("newRegulationNonPartText")}</Typography>
          </Box>
          <Box>
            <Typography variant="h5">{t("newRegulationDuration")}</Typography>
            <Typography>{t("newRegulationDurationText")}</Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography variant="h5">{t("newRegulationPrize")}</Typography>
            <Typography>{t("newRegulationPrizeText1")}</Typography>
            <Typography>{t("newRegulationPrizeText2")} </Typography>
            <Typography>{t("newRegulationPrizeText3")}</Typography>
            <Typography>
              {t("newRegulationPrizeText4")}
              <ul>
                <li> {t("newRegulationPrizeText5")}</li>
                <li> {t("newRegulationPrizeText6")}</li>
                <li> {t("newRegulationPrizeText7")}</li>
              </ul>
            </Typography>
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
            <Typography variant="h5"> {t("newRegulationPoints")}</Typography>
            <Typography>{t("newRegulationPointsText1")}</Typography>
            <Typography>{t("newRegulationPointsText2")}</Typography>
            <Typography>{t("newRegulationPointsText3")}</Typography>
            <Typography>{t("newRegulationPointsText4")}</Typography>
            <Typography>{t("newRegulationPointsText5")}</Typography>
            <Typography>{t("newRegulationPointsText6")}</Typography>
            <Typography>{t("newRegulationPointsText7")}</Typography>
            <Typography>{t("newRegulationPointsText8")}</Typography>
            <Typography>{t("newRegulationPointsText9")}</Typography>
          </Box>
        </Box>
      </Box>

      <Footer relative={"relative"} />
    </Box>
  );
};

export default Agap2work;
