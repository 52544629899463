import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import Leaderboard from "../../components/Dashboard/LeaderBoard";
import LeaderboardTable from "../../components/Dashboard/LeaderboardTable";
import Me from "../../components/Dashboard/Me";
import Footer from "../../components/core/Footer";
import Timestamp from "../../components/Dashboard/Timestamp";
import SendCV from "../../components/Dashboard/SendCV";
import DashNavbar from "../../components/Dashboard/DashNavbar";
import MyStats from "../../components/Dashboard/MyStats";
import Admin from "../../components/Dashboard/Admin";
import { Box, Typography, Stack, Breadcrumbs, TextField } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import FolderOffIcon from "@mui/icons-material/FolderOff";
import { useTranslation } from "react-i18next";

export default function DashLayout(props) {
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const userFromRedux = useSelector((state) => state.user.value);
  const token = userFromRedux.token;
  const [arrLengthToSet, setArrLengthToSet] = useState(null || 5);
  const [arrLength, setArrLength] = useState({
    value: 4,
    id: "",
  });
  const [admin, setAdmin] = useState(false);
  const [reloadDB, setReloadDB] = useState(false);
  const [index, setIndex] = useState(0);
  const [ranking, setRanking] = useState([]);
  const [myRanking, setMyRanking] = useState({});
  const [CVList, setCVList] = useState([]);
  const [showLeaderboard, setShowLeaderboard] = useState(true);
  const [showMe, setShowMe] = useState(false);
  const [showAdmin, setShowAdmin] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [loadingCV, setLoadingCV] = useState(false);
  const url = process.env.REACT_APP_MAIN_URL ?? process.env.PUBLIC_URL;

  function padTo2Digits(num) {
    return num.toString().padStart(2, "0");
  }

  function formatDate(date) {
    return (
      [
        date.getFullYear(),
        padTo2Digits(date.getMonth() + 1),
        padTo2Digits(date.getDate()),
      ].join("-") +
      " " +
      [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(":")
    );
  }

  const getRanking = async () => {
    const bearer = "Bearer " + token;

    fetch(url + "api/Statistics/Ranking", {
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: bearer,
      },
    })
      .then((response) => {
        let res = response.json();

        return res;
      })
      .then((data) => {
        setRanking(data);
      })
      .catch(() => {
        enqueueSnackbar(`${t("toastError")}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      })
      .finally();
  };

  const setArrLengthToDB = async () => {
    const bearer = "Bearer " + token;
    setReloadDB(true);
    let dataToSend = {
      id: Number(arrLength.id),
      value: arrLengthToSet.toString(),
    };
    fetch(url + "api/ApplicationConfiguration", {
      method: "PUT",
      headers: {
        Accept: "*/*",
        Authorization: bearer,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataToSend),
    })
      .then((response) => {
        if (response.ok) {
          setArrLength(dataToSend);
        }
      })
      .catch(() => {
        enqueueSnackbar(`${t("toastError")}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      })
      .finally(setReloadDB(false));
  };
  const getArrLength = async () => {
    const bearer = "Bearer " + token;
    fetch(url + "api/ApplicationConfiguration", {
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: bearer,
      },
    })
      .then((response) => {
        let res = response.json();
        return res;
      })
      .then((data) => {
        setArrLength({
          id: data[0].id.toString(),
          value: Number(data[0].value),
        });
      })
      .catch(() => {
        enqueueSnackbar(`${t("toastError")}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      })
      .finally();
  };

  const getListOfCVs = async () => {
    const bearer = "Bearer " + token;
    const myData = {
      approved: 0,
      denied: 0,
      pending: 0,
    };
    fetch(url + "api/CvInformation", {
      method: "GET",
      headers: {
        Accept: "*/*",
        Authorization: bearer,
      },
    })
      .then((response) => {
        let res = response.json();
        return res;
      })
      .then((data) => {
        data.map((element) => {
          let consentDate = formatDate(new Date(element.consentDate));
          let createdDate = formatDate(new Date(element.createdDate));
          element.createdDate = createdDate;
          element.consentDate =
            consentDate === "1-01-01 00:00" ? "N/A" : consentDate;
          if (element.status === 0) {
            myData.approved = myData.approved + 1;
          } else if (element.status === 1) {
            myData.denied = myData.denied + 1;
          } else {
            myData.pending = myData.pending + 1;
          }
          setMyRanking(myData);
          setCVList(data);
          setReloadDB(false);
        });
      })
      .catch(() => {
        enqueueSnackbar(`${t("toastError")}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      })
      .finally();
  };

  const handleShow = () => {
    if (index === 1) {
      setShowMe(true);
      setShowLeaderboard(false);
      setShowMobileMenu(false);
      setShowAdmin(false);
    } else if (index === 2) {
      setShowAdmin(true);
      setShowLeaderboard(false);
      setShowMe(false);
      setShowMobileMenu(false);
    } else {
      setShowLeaderboard(true);
      setShowMe(false);
      setShowMobileMenu(false);
      setShowAdmin(false);
    }
  };

  const handleArrLength = (e) => {
    if (e.target.value >= 0) {
      setArrLength(e.target.value);
    } else {
      enqueueSnackbar(`${t("toastNumbers")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    }
  };

  useEffect(() => {
    if (userFromRedux) {
      setAdmin(userFromRedux.admin);
    }
    getRanking();
    getListOfCVs();
    getArrLength();
  }, [loadingCV, reloadDB]);

  useEffect(() => {
    handleShow();
  }, [index]);

  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      {/* navbar */}
      <DashNavbar
        showMobileMenu={showMobileMenu}
        setShowMobileMenu={setShowMobileMenu}
        index={index}
        setIndex={setIndex}
        admin={admin}
      />
      {/* Breadcrumbs */}
      <Box sx={{ pl: "40px" }}>
        <Stack spacing={2}>
          <Breadcrumbs separator="›" aria-label="breadcrumb">
            <Typography sx={{ fontFamily: "Montserrat" }}>Home</Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>Dashboard</Typography>
            {index === 0 && (
              <Typography sx={{ fontFamily: "Montserrat" }}>
                {t("leaderboard")}
              </Typography>
            )}
            {index === 1 && (
              <Typography sx={{ fontFamily: "Montserrat" }}>
                {t("myaccount")}
              </Typography>
            )}
            {index === 2 && (
              <Typography sx={{ fontFamily: "Montserrat" }}>ADMIN</Typography>
            )}
          </Breadcrumbs>
        </Stack>
      </Box>
      {/* content */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          minHeight: "85%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            width: "100%",
            height: "100%",
          }}
        >
          {showLeaderboard && (
            <Box
              sx={{
                width: "100%",
              }}
            >
              {/* ----------------------------- 4cards ----------------------------------------- */}
              <MyStats user={userFromRedux} myRanking={myRanking} />

              {/* ----------------------------- SendCV ----------------------------------------- */}
              {!admin && (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <SendCV loadingCV={setLoadingCV} />
                </Box>
              )}

              {/* ----------------------------- Chart ----------------------------------------- */}

              <Box
                sx={{
                  display: "flex",
                  p: "40px",
                  width: "100%",
                  minHeight: "500px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    flexWrap: "wrap",
                    gap: "30px",
                    flexDirection: `${admin ? "column-reverse" : "column"}`,

                    pb: "100px",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      minHeight: "300px",
                      justifyContent: "space-between",
                      display: "flex",
                      flexDirection: { xs: "column-reverse", md: "row" },
                      gap: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "450px",
                        width: { xs: "100%", md: "50%" },
                        p: "20px",
                        borderRadius: "15px",
                        backgroundColor: "white",
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                      }}
                    >
                      <Box
                        sx={{
                          pl: "20px",
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography
                          variant="h3"
                          sx={{
                            fontSize: "1rem",
                            fontWeight: 700,
                            py: "40px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {t("leaderboardRanking")}
                        </Typography>
                        {admin && (
                          <Box
                            sx={{ display: "flex", flexDirection: "column" }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography sx={{ fontFamily: "Montserrat" }}>
                                List Limit:
                              </Typography>
                              <CheckIcon
                                sx={{ color: "green", fontWeight: 800 }}
                                onClick={() => setArrLengthToDB()}
                              />
                            </Box>
                            <input
                              type={"number"}
                              value={arrLengthToSet}
                              onChange={(e) =>
                                setArrLengthToSet(e.target.value)
                              }
                            />
                          </Box>
                        )}
                      </Box>
                      {!ranking.length ? (
                        <Box
                          sx={{
                            height: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                          }}
                        >
                          <FolderOffIcon sx={{ fontSize: "7rem" }} />
                          <Typography>{t("nodata")}</Typography>
                        </Box>
                      ) : (
                        <Leaderboard
                          boardList={ranking}
                          arrLength={arrLength}
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        minHeight: "450px",
                        width: { xs: "100%", md: "50%" },
                        p: "20px",
                        borderRadius: "15px",
                        backgroundColor: "white",
                        boxShadow:
                          "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                      }}
                    >
                      <Box sx={{ pl: "20px" }}>
                        <Typography
                          variant="h3"
                          sx={{
                            fontSize: "1rem",
                            fontWeight: 700,
                            py: "40px",
                            fontFamily: "Montserrat",
                          }}
                        >
                          {t("leaderboardTable")} CV's
                        </Typography>
                      </Box>
                      <LeaderboardTable
                        boardList={ranking}
                        arrLength={arrLength.value}
                      />
                    </Box>
                  </Box>
                  {/* ----------------------------- Timestamps ----------------------------------------- */}

                  <Box
                    sx={{
                      width: "100%",
                      minHeight: "300px",
                      p: "40px",
                      pb: "50px",
                      backgroundColor: "white",
                      borderRadius: "15px",

                      boxShadow:
                        "rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px",
                    }}
                  >
                    <Box sx={{ width: "100%", height: "100%", mb: "20px" }}>
                      <Typography
                        variant="h3"
                        sx={{
                          fontSize: "1rem",
                          fontWeight: 700,
                          pb: "40px",
                          fontFamily: "Montserrat",
                        }}
                      >
                        {t("timestampsTitle")}
                      </Typography>
                      <Timestamp
                        user={userFromRedux}
                        originalData={CVList}
                        setReloadDB={setReloadDB}
                      />
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {showMe && <Me />}
          {showAdmin && <Admin />}
        </Box>
      </Box>
      <Footer fixed={showMe ? "fixed" : "absolute"} />
    </Box>
  );
}
