import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes, Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { setToken } from "./redux/slices/tokenSlice";
import Qualidade from "./pages/Qualidade";
import Regulamento from "./pages/Regulamento.jsx";
import Home from "./pages/Home/Home";

import Dashboard from "./pages/Dashboards/Dashboard";
import { Layout } from "./components/Layout";
import "./custom.css";
import Politicas from "./pages/Politicas";
import EmailConfirmation from "./pages/EmailConfirmation";
import StatusConfirm from "./pages/StatusConfirm";
import StatusDeny from "./pages/StatusDeny";
import Agap2work from "./pages/Agap2work";
import Recovery from "./pages/Recovery";

export default function App() {
  const token = useSelector((state) => state.token.value);
  const user = useSelector((state) => state.user.value);
  const dispatch = useDispatch();

  const RouteProtection = ({ children }) => {
    if (!token) {
      return <Navigate to="/" />;
    } else {
      return children;
    }
  };

  useEffect(() => {
    let tokenFromLS = sessionStorage.getItem("CvChallenge");
    if (tokenFromLS) {
      dispatch(setToken(tokenFromLS));
    }
  }, [token]);
  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/politicas-de-privacidade" element={<Politicas />} />
        <Route path="/politicas-de-qualidade" element={<Qualidade />} />
        <Route
          path="/regulamento-de-comunicacao-de-irregularidades"
          element={<Regulamento />}
        />
        <Route
          path="/dashboard"
          element={
            <RouteProtection>
              <Dashboard />
            </RouteProtection>
          }
        />
        <Route path="/agap2work" element={<Agap2work />} />
        <Route path="/email_confirmation" element={<EmailConfirmation />} />
        <Route path="/confirm_policies" element={<StatusConfirm />} />
        <Route path="/deny_policies" element={<StatusDeny />} />
        <Route path="/recovery" element={<Recovery />} />
      </Routes>
    </Layout>
  );
}
