import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.svg";
import logo2 from "../../assets/images/agap2work_banner.svg";
import LeaderboardIcon from "../../assets/icons/estatistica.svg";
import AccountCircleIcon from "../../assets/icons/my_account.svg";
import Logout from "../../assets/icons/logout.svg";
import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { Link } from "react-router-dom";
import {
  Box,
  List,
  FormControl,
  Select,
  MenuItem,
  ListItem,
  ListItemAvatar,
  Avatar,
  Typography,
  CardMedia,
} from "@mui/material";
import { removeToken } from "../../redux/slices/tokenSlice";
import portugal from "../../assets/icons/flags/portugal.png";
import uk from "../../assets/icons/flags/uk.png";

export default function DashNavbar({
  showMobileMenu,
  setShowMobileMenu,
  index,
  setIndex,
}) {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const [language, setLanguage] = useState("english");

  const handleLogout = () => {
    dispatch(removeToken());
    sessionStorage.removeItem("CvChallenge");
    navigate("/");
  };
  const handleMobileMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };
  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    if (language === "english") {
      i18n.changeLanguage("pt");
    } else {
      i18n.changeLanguage("en");
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: "#000",
        p: "5px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "reltive",
        mb: "50px",
      }}
    >
      <Box
        sx={{
          position: "relative",
          pl: "40px",
          display: "flex",
          alignItems: "center",
          gap: "15px",
          maxWidth: { xs: "50%", md: "30%" },
        }}
      >
        <Link to={"/"}>
          <CardMedia
            sx={{
              pt: { xs: "7px", md: "12px" },
              width: { xs: "100px", md: "200px" },
            }}
            component="img"
            src={logo}
          />
        </Link>
        <Link to={"/"}>
          <CardMedia
            sx={{ width: { xs: "70px", md: "100px" } }}
            component="img"
            src={logo2}
          />
        </Link>
      </Box>
      <Box sx={{ display: "flex", pr: "40px" }}>
        <List
          sx={{
            width: "100%",
            height: "100%",
            display: { xs: "none", md: "flex" },
            flexDirection: "row",
            fontFamily: "Montserrat",
            alignItems: "center",
            gap: "10px",
          }}
        >
          {user.admin && (
            <ListItem
              sx={{
                cursor: "pointer",
                maxWidth: "20%",
                display: "flex",
                gap: "10px",
                minWidth: "max-content",
                borderBottom: `${
                  index === 2 ? "2px solid #ffcd00" : "2px solid transparent"
                }`,
                opacity: `${index === 2 ? 0.7 : 1}`,
              }}
              onClick={() => setIndex(2)}
            >
              <ListItemAvatar sx={{ minWidth: "0" }}>
                <Avatar
                  sx={{
                    backgroundColor: "#ffcd00",
                    width: "30px",
                    height: "30px",
                  }}
                >
                  <PersonAddAltIcon sx={{ width: "20px", color: "black" }} />
                </Avatar>
              </ListItemAvatar>
              <Typography
                sx={{
                  color: "white",
                  fontWeight: 700,
                  fontFamily: "Montserrat",
                  fontSize: "0.8rem",
                  minWidth: "max-content",
                }}
              >
                ADMIN
              </Typography>
            </ListItem>
          )}

          <ListItem
            sx={{
              cursor: "pointer",
              maxWidth: "20%",
              display: "flex",
              gap: "10px",
              minWidth: "max-content",
              borderBottom: `${
                index === 0 ? "2px solid #ffcd00" : "2px solid transparent"
              }`,
              opacity: `${index === 0 ? 0.7 : 1}`,
            }}
            onClick={() => setIndex(0)}
          >
            <ListItemAvatar sx={{ minWidth: "0" }}>
              <Avatar
                sx={{
                  backgroundColor: "#ffcd00",
                  width: "30px",
                  height: "30px",
                }}
              >
                <CardMedia
                  sx={{ width: "20px" }}
                  component="img"
                  src={LeaderboardIcon}
                />
              </Avatar>
            </ListItemAvatar>
            <Typography
              sx={{
                color: "white",
                fontWeight: 700,
                fontSize: "0.8rem",
                fontFamily: "Montserrat",
                minWidth: "max-content",
              }}
            >
              {t("leaderboard")}
            </Typography>
          </ListItem>
          <ListItem
            sx={{
              cursor: "pointer",
              maxWidth: "20%",
              display: "flex",
              gap: "10px",
              minWidth: "max-content",
              borderBottom: `${
                index === 1 ? "2px solid #ffcd00" : "2px solid transparent"
              }`,
              opacity: `${index === 1 ? 0.7 : 1}`,
            }}
            onClick={() => setIndex(1)}
          >
            <ListItemAvatar sx={{ minWidth: "0" }}>
              <Avatar
                sx={{
                  backgroundColor: "#ffcd00",
                  width: "30px",
                  height: "30px",
                }}
              >
                <CardMedia
                  sx={{ width: "20px" }}
                  component="img"
                  src={AccountCircleIcon}
                />
              </Avatar>
            </ListItemAvatar>
            <Typography
              sx={{
                color: "white",
                fontWeight: 700,
                fontSize: "0.8rem",
                minWidth: "max-content",
                fontFamily: "Montserrat",
              }}
            >
              {t("myaccount")}
            </Typography>
          </ListItem>
          <ListItem
            sx={{
              cursor: "pointer",
              maxWidth: "20%",
              display: "flex",
              gap: "10px",
              minWidth: "max-content",
            }}
            onClick={handleLogout}
          >
            <ListItemAvatar sx={{ minWidth: "0" }}>
              <Avatar
                sx={{
                  backgroundColor: "#ffcd00",
                  width: "30px",
                  height: "30px",
                }}
              >
                <CardMedia
                  sx={{ width: "20px" }}
                  component="img"
                  src={Logout}
                />
              </Avatar>
            </ListItemAvatar>
            <Typography
              sx={{
                color: "white",
                fontWeight: 700,
                fontSize: "0.8rem",
                fontFamily: "Montserrat",
                minWidth: "max-content",
              }}
            >
              Log Out
            </Typography>
          </ListItem>
          <ListItem>
            <FormControl variant="standard">
              <Select
                sx={{ color: "white", border: "none" }}
                value={language}
                defaultValue={"english"}
                onChange={handleLanguageChange}
              >
                <MenuItem value="portugues">
                  <Avatar alt="Bandeira Portuguesa" src={portugal} />
                </MenuItem>
                <MenuItem value="english">
                  <Avatar alt="English Flag" src={uk} />
                </MenuItem>
              </Select>
            </FormControl>
          </ListItem>
        </List>
        {/* menu mobile */}
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
          }}
        >
          <ListItem sx={{ cursor: "pointer" }} onClick={handleMobileMenu}>
            <ListItemAvatar>
              <Avatar sx={{ backgroundColor: "#ffcd00" }}>
                <MenuIcon />
              </Avatar>
            </ListItemAvatar>
          </ListItem>
          {showMobileMenu && (
            <Box
              sx={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100vw",
                height: "100vh",
                zIndex: 6,
                backgroundColor: "#f1f1f1",
              }}
            >
              <List
                sx={{
                  width: "100%",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  justifyContent: "space-around",
                  position: "relative",
                }}
              >
                <Box
                  sx={{ position: "absolute", top: 20, right: 20 }}
                  onClick={handleMobileMenu}
                >
                  <CloseIcon />
                </Box>
                {user.admin && (
                  <ListItem
                    sx={{ cursor: "pointer" }}
                    onClick={() => setIndex(2)}
                  >
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: "#ffcd00" }}>
                        <PersonAddAltIcon
                          sx={{ width: "20px", color: "black" }}
                        />
                      </Avatar>
                    </ListItemAvatar>
                    <Typography
                      sx={{ fontWeight: 800, fontFamily: "Montserrat" }}
                    >
                      ADMIN
                    </Typography>
                  </ListItem>
                )}
                <ListItem
                  sx={{ cursor: "pointer" }}
                  onClick={() => setIndex(0)}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: "#ffcd00" }}>
                      <CardMedia
                        component="img"
                        sx={{ width: "20px" }}
                        src={LeaderboardIcon}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <Typography
                    sx={{ fontWeight: 800, fontFamily: "Montserrat" }}
                  >
                    {" "}
                    {t("leaderboard")}
                  </Typography>
                </ListItem>
                <ListItem
                  sx={{ cursor: "pointer" }}
                  onClick={() => setIndex(1)}
                >
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: "#ffcd00" }}>
                      <CardMedia
                        sx={{ width: "20px" }}
                        component="img"
                        src={AccountCircleIcon}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <Typography
                    sx={{ fontWeight: 800, fontFamily: "Montserrat" }}
                  >
                    {t("myaccount")}
                  </Typography>
                </ListItem>
                <ListItem sx={{ cursor: "pointer" }} onClick={handleLogout}>
                  <ListItemAvatar>
                    <Avatar sx={{ backgroundColor: "#ffcd00" }}>
                      <CardMedia
                        sx={{ width: "20px" }}
                        component="img"
                        src={Logout}
                      />
                    </Avatar>
                  </ListItemAvatar>
                  <Typography
                    sx={{ fontWeight: 800, fontFamily: "Montserrat" }}
                  >
                    Log Out
                  </Typography>
                </ListItem>
              </List>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
}
