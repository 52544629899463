import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import logo from "../../assets/images/logo.svg";
import { Link } from "react-router-dom";
import {
  Box,
  List,
  FormControl,
  Select,
  MenuItem,
  Avatar,
  CardMedia,
} from "@mui/material";
import portugal from "../../assets/icons/flags/portugal.png";
import uk from "../../assets/icons/flags/uk.png";

export default function SimpleNav() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [language, setLanguage] = useState("english");

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    if (language === "english") {
      i18n.changeLanguage("pt");
    } else {
      i18n.changeLanguage("en");
    }
  };
  return (
    <Box
      sx={{
        backgroundColor: "#000",
        p: "5px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        position: "reltive",
        mb: "50px",
        borderTop: "0.5rem solid #ffcd00",
      }}
    >
      <Box sx={{ position: "relative", pl: "40px" }}>
        <Link to={"/"}>
          <CardMedia sx={{ width: "40%" }} component="img" src={logo} />
        </Link>
      </Box>
      <Box sx={{ display: "flex", pr: "40px" }}>
        <List
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <FormControl variant="standard">
            <Select
              sx={{ color: "white", border: "none" }}
              value={language}
              defaultValue={"english"}
              onChange={handleLanguageChange}
            >
              <MenuItem value="portugues">
                <Avatar alt="Bandeira Portuguesa" src={portugal} />
              </MenuItem>
              <MenuItem value="english">
                <Avatar alt="English Flag" src={uk} />
              </MenuItem>
            </Select>
          </FormControl>
        </List>
      </Box>
    </Box>
  );
}
