import React, { useState, useEffect } from "react";
import Footer from "../components/core/Footer";
import SimpleNav from "../components/core/SimpleNav";
import { Box, Typography } from "@mui/material";
import HomeImg from "./../assets/images/home_bg.png";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";

const StatusConfirm = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const url = process.env.REACT_APP_MAIN_URL ?? process.env.PUBLIC_URL;

  let apiRequest = async () => {
    const UrlQueryString = window.location.search;
    const queryParams = new URLSearchParams(UrlQueryString);
    let toSend = {
      email: queryParams.get("email"),
      token: queryParams.get("token").split(" ").join("+"),
      id: Number(queryParams.get("token").split(" ").join("+")),
      consent: true,
    };

    try {
      const response = await fetch(url + `api/CvInformation/consent`, {
        method: "POST",
        headers: {
          Accept: "*/*",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(toSend),
      });
      if (response.ok) {
        setLoading(false);
      }
    } catch (error) {
      enqueueSnackbar(`${t("toastError")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    } finally {
    }
  };
  useEffect(() => {
    apiRequest();
  }, []);

  return (
    <Box
      sx={{
        minHeight: "90vh",
        backgroundImage: `url(${HomeImg})`,
        backgroundSize: "cover",
      }}
    >
      <SimpleNav />
      <Box
        sx={{
          p: "20px",
          maxWidth: { xs: "100%", md: "50%" },
          mt: { xs: "auto", md: "10%" },
          pl: "5%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            gap: "100px",
            mt: "100px",
          }}
        >
          <Typography sx={{ textAlign: "center" }} variant="h4">
            {loading ? "Loading..." : t("policyConfirmationText")}
          </Typography>
        </Box>
      </Box>

      <Footer relative={"relative"} />
    </Box>
  );
};

export default StatusConfirm;
