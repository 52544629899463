import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import RegulationModal from "../../components/RegulationModal";
import ModalComponent from "../../components/Modal";
import Footer from "../../components/core/Footer";
import {
  Button,
  List,
  FormControl,
  Select,
  MenuItem,
  Avatar,
  Typography,
  Box,
  CardMedia,
} from "@mui/material";
import portugal from "../../assets/icons/flags/portugal.png";
import uk from "../../assets/icons/flags/uk.png";
import HomeImg from "../../assets/images/home_bg.png";
import Logo from "../../assets/images/agap2iT.svg";

const Home = (props) => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [showRegulation, setShowRegulation] = useState(false);
  const [language, setLanguage] = useState("english");
  const navigate = useNavigate();

  const handleLanguageChange = (e) => {
    setLanguage(e.target.value);
    if (language === "english") {
      i18n.changeLanguage("pt");
    } else {
      i18n.changeLanguage("en");
    }
  };

  const handleModal = () => {
    let token = sessionStorage.getItem("CvChallenge");
    if (token) {
      navigate("/dashboard");
      return;
    }
    setShowModal(!showModal);
  };
  const handleRegulation = () => {
    setShowRegulation(!showRegulation);
  };

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          minHeight: "90vh",
          backgroundImage: `url(${HomeImg})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <CardMedia
            sx={{ width: "200px", p: "20px" }}
            component="img"
            src={Logo}
          />
          <List
            sx={{
              mr: "11%",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: "10px",
            }}
          >
            <FormControl variant="standard">
              <Select
                sx={{ color: "white", border: "none" }}
                value={language}
                defaultValue={"english"}
                onChange={handleLanguageChange}
              >
                <MenuItem value="portugues">
                  <Avatar alt="Bandeira Portuguesa" src={portugal} />
                </MenuItem>
                <MenuItem value="english">
                  <Avatar alt="English Flag" src={uk} />
                </MenuItem>
              </Select>
            </FormControl>
          </List>
        </Box>

        <Box
          sx={{
            position: "absolute",
            width: "min-content",
            top: { xs: "50%", md: "30%", xl: "31%" },
            left: { xs: "50%", md: "10%", xl: "16%" },
            transform: { xs: "translate(-50%, -50%)", md: "unset" },
            fontFamily: "Cags_Hetonal",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            gap: "80px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Typography
              variant="h1"
              sx={{
                fontFamily: "Cags_Hetonal",
                fontSize: { xs: "3rem", md: "7rem" },
              }}
            >
              agap2work
            </Typography>
            <Typography sx={{ fontFamily: "Montserrat" }}>
              {t("homedesafio")}{" "}
              <span
                style={{
                  fontStyle: "italic",
                  textDecoration: "underline",
                  cursor: "pointer",
                }}
              >
                <a href="/agap2work" target="_blank" rel="noopener noreferrer">
                  {t("homehere")}!
                </a>
              </span>
            </Typography>
          </Box>

          <Button
            onClick={handleModal}
            sx={{
              border: "1px solid black",
              backgroundColor: "black",
              color: "#E4E5E9",
              borderRadius: "5px",
              fontFamily: "Montserrat",
              px: "5vh",
              "&:hover": {
                color: "black",
                backgroundColor: "transparent",
              },
            }}
          >
            {t("homebtn")}
          </Button>
        </Box>
        {showModal && <ModalComponent show={showModal} setShow={handleModal} />}
      </Box>
      <Footer relative={"relative"} nomargintop={true} />
    </Box>
  );
};

export default Home;
