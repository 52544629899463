import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";

export default function StickyHeadTable({ boardList, arrLength }) {
  const { t } = useTranslation();
  const userFromRedux = useSelector((state) => state.user.value);
  const admin = userFromRedux.admin;
  const user = userFromRedux.email;
  const [listToShow, setListToShow] = useState([]);

  useEffect(() => {
    let sortedArray = [...boardList].sort((a, b) => {
      if (b.accepted === a.accepted) {
        return b.pending - a.pending;
      } else {
        return b.accepted - a.accepted;
      }
    });
    sortedArray.map((element, index) => {
      element.name = index + 1 + " " + element.user;
      sortedArray.length = arrLength.value;
      setListToShow(sortedArray);
      return;
    });
    sortedArray.length = arrLength.value || 4;
    setListToShow(sortedArray);
  }, [arrLength, boardList]);
  const renderLegend = (props) => {
    const { payload } = props;

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "50px",
          flexDirection: "row-reverse",
        }}
      >
        {payload.map((entry, index) => (
          <p
            key={`item-${index}`}
            style={{
              color:
                entry.value === "accepted"
                  ? "#ABCAA9"
                  : entry.value === "declined"
                  ? "#F99797"
                  : "#CAC7C7",
            }}
          >
            {entry.value === "accepted"
              ? `${t("cvapproved")}`
              : entry.value === "declined"
              ? `${t("cvdenied")}`
              : `${t("cvpending")}`}
          </p>
        ))}
      </div>
    );
  };
  return (
    <ResponsiveContainer
      width="100%"
      height="100%"
      sx={{ backgroundColor: "white" }}
    >
      <BarChart
        barCategoryGap={"1%"}
        barGap={"1%"}
        layout="vertical"
        data={listToShow}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          type="number"
          tick={{ fill: "#000" }}
          fontSize={"7px"}
          allowDecimals={false}
        />
        <YAxis
          dataKey="name"
          type="category"
          fontSize={"7px"}
          tick={{ fill: "#000" }}
        />
        <Legend content={renderLegend} />
        <Bar barSize={10} dataKey="accepted" stackId="a" fill="#ABCAA9" />
        <Bar barSize={10} dataKey="declined" stackId="a" fill="#F99797" />
        <Bar barSize={10} dataKey="pending" stackId="a" fill="#CAC7C7" />
      </BarChart>
    </ResponsiveContainer>
  );
}
