import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useSnackbar } from "notistack";
import { Box, Typography, FormControl, Button, TextField } from "@mui/material";
import ShowIcon from "@mui/icons-material/VisibilityOutlined";
import HideIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useTranslation } from "react-i18next";

export default function Me() {
  const { t } = useTranslation();
  const userFromRedux = useSelector((state) => state.user.value);
  const { enqueueSnackbar } = useSnackbar();
  const [showUserPass, setShowUserPass] = useState(false);
  const [showUserNewPass, setShowUserNewPass] = useState(false);
  const [showUserConfirmPass, setShowUserConfirmPass] = useState(false);
  const [passLength, setPassLength] = useState(false);
  const [alphaNum, setAlphaNum] = useState(false);
  const [passUpper, setPassUpper] = useState(false);
  const [passLower, setPassLower] = useState(false);
  const [passNumber, setPassNumber] = useState(false);
  const [userOldPassword, setUserOldPassword] = useState("");
  const [userRegisterPassword, setUserRegisterPassword] = useState("");
  const [userRegisterConfirmPassword, setUserRegisterConfirmPassword] =
    useState("");
  const url = process.env.REACT_APP_MAIN_URL ?? process.env.PUBLIC_URL;
  const myAccountCondition =
    userOldPassword.length &&
    userRegisterPassword.length &&
    userRegisterConfirmPassword.length;
  const handleSubmit = async () => {
    const userToSend = {
      email: userFromRedux.email,
      password: userOldPassword,
      newPassword: userRegisterPassword,
    };
    if (userRegisterPassword !== userRegisterConfirmPassword) {
      enqueueSnackbar(`${t("passNoMatch")}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
      return;
    }
    try {
      const token = userFromRedux.token;
      const bearer = "Bearer " + token;
      const response = await fetch(url + "api/Account/changePassword", {
        method: "POST",
        headers: {
          Authorization: bearer,
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userToSend),
      });
      const data = await response.json();

      if (response.ok) {
        enqueueSnackbar(`${t("changePassSuccess")}`, {
          variant: "success",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
        document.getElementById("changePassForm").reset();
      } else {
        let errorFromDb = data[0].description;
        enqueueSnackbar(`${(t("toastError"), errorFromDb)}`, {
          variant: "error",
          anchorOrigin: {
            horizontal: "center",
            vertical: "top",
          },
        });
      }
    } catch (error) {
      enqueueSnackbar(`${(t("toastError"), error)}`, {
        variant: "error",
        anchorOrigin: {
          horizontal: "center",
          vertical: "top",
        },
      });
    }
  };
  let confirmPassSteps = () => {
    const passLengthRegex = /(^.{14,}$)/;
    const passNumberRegex = /[0-9]/;
    const alphaNumRegex = /[^A-Za-z0-9]/;
    const upperPassRegex = /[A-Z]/;
    const lowerPassRegex = /[a-z]/;
    if (passLengthRegex.test(userRegisterPassword)) {
      setPassLength(true);
    } else {
      setPassLength(false);
    }
    if (passNumberRegex.test(userRegisterPassword)) {
      setPassNumber(true);
    } else {
      setPassNumber(false);
    }
    if (alphaNumRegex.test(userRegisterPassword)) {
      setAlphaNum(true);
    } else {
      setAlphaNum(false);
    }
    if (lowerPassRegex.test(userRegisterPassword)) {
      setPassLower(true);
    } else {
      setPassLower(false);
    }
    if (upperPassRegex.test(userRegisterPassword)) {
      setPassUpper(true);
    } else {
      setPassUpper(false);
    }
  };

  useEffect(() => {
    confirmPassSteps();
  }, [userRegisterPassword]);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        p: "0 0  10% 0",
        width: { xs: "100%", md: "inherit" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: { xs: "20px", md: "70px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "20px",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: { xs: "1rem", md: "3rem" },
              color: "white",
              fontWeight: 700,
              width: "50%",
            }}
          >
            Email:
          </Typography>
          <Typography
            sx={{
              fontSize: { xs: "1rem", md: "3rem" },
              color: "white",
              textDecoration: "underline",
            }}
          >
            {userFromRedux.email}
          </Typography>
        </Box>
        <Typography
          variant="h4"
          sx={{
            fontSize: { xs: "1rem", md: "2rem" },
            color: "white",
            fontWeight: 500,
            width: "50%",
            mt: { xs: "-20px", sm: 0 },
            mb: { xs: "20px", sm: 0 },
          }}
        >
          {t("changePass")}
        </Typography>
        <form
          id="changePassForm"
          style={{
            color: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            marginTop: "-50px",
          }}
        >
          <Box
            sx={{ display: "flex", flexDirection: { xs: "column", sm: "row" } }}
          >
            <Box
              sx={{
                width: "100%",
                display: "flex",

                alignItems: "end",
              }}
            >
              <FormControl
                sx={{
                  backgroundColor: "rgba(255,255,255, 0.2)",
                  m: 1,
                  position: "relative",
                }}
                required
              >
                <TextField
                  required={true}
                  sx={{ width: "100%" }}
                  id="registerName"
                  label={t("oldPass")}
                  type={showUserPass ? "text" : "password"}
                  variant="standard"
                  onChange={(e) => setUserOldPassword(e.target.value)}
                />
                {showUserPass ? (
                  <ShowIcon
                    onClick={() => setShowUserPass(!showUserPass)}
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      color: "black",
                    }}
                  />
                ) : (
                  <HideIcon
                    onClick={() => setShowUserPass(!showUserPass)}
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      color: "black",
                    }}
                  />
                )}
              </FormControl>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",

                alignItems: "end",
              }}
            >
              <FormControl
                sx={{ backgroundColor: "rgba(255,255,255, 0.2)", m: 1 }}
                required
              >
                <TextField
                  required={true}
                  sx={{ width: "100%" }}
                  id="registerPassword"
                  label={t("newPass")}
                  type={showUserNewPass ? "text" : "password"}
                  variant="standard"
                  onChange={(e) => setUserRegisterPassword(e.target.value)}
                />
                {showUserNewPass ? (
                  <ShowIcon
                    onClick={() => setShowUserNewPass(!showUserNewPass)}
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      color: "black",
                    }}
                  />
                ) : (
                  <HideIcon
                    onClick={() => setShowUserNewPass(!showUserNewPass)}
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      color: "black",
                    }}
                  />
                )}
              </FormControl>
            </Box>

            <Box sx={{ width: "100%", display: "flex", alignItems: "end" }}>
              <FormControl
                sx={{ backgroundColor: "rgba(255,255,255, 0.2)", m: 1 }}
                variant="outlined"
                required
              >
                <TextField
                  required={true}
                  sx={{ width: "100%" }}
                  id="registerConfirmPassword"
                  label={t("newPassConfirm")}
                  type={showUserConfirmPass ? "text" : "password"}
                  variant="standard"
                  onChange={(e) =>
                    setUserRegisterConfirmPassword(e.target.value)
                  }
                />
                {showUserConfirmPass ? (
                  <ShowIcon
                    onClick={() => setShowUserConfirmPass(!showUserConfirmPass)}
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      color: "black",
                    }}
                  />
                ) : (
                  <HideIcon
                    onClick={() => setShowUserConfirmPass(!showUserConfirmPass)}
                    sx={{
                      position: "absolute",
                      right: 0,
                      bottom: 0,
                      color: "black",
                    }}
                  />
                )}
              </FormControl>
            </Box>
          </Box>
          <Typography
            variant="caption"
            sx={{
              fontSize: "10px",
              color: "gray",
            }}
          >
            {t("passConditions")}
            <ul>
              <li style={{ color: passLength ? "green" : "red" }}>
                {t("passCharacters")}
              </li>
              <li style={{ color: alphaNum ? "green" : "red" }}>
                {t("passAlfanumeric")}
              </li>
              <li style={{ color: passNumber ? "green" : "red" }}>
                {t("passDigit")}{" "}
              </li>
              <li style={{ color: passUpper ? "green" : "red" }}>
                {t("passUppercase")}{" "}
              </li>
              <li style={{ color: passLower ? "green" : "red" }}>
                {t("passLowercase")}{" "}
              </li>
            </ul>
          </Typography>
          <Box sx={{ width: "50%" }}>
            <Button
              disabled={!myAccountCondition}
              sx={{ width: "100%" }}
              variant="contained"
              onClick={handleSubmit}
            >
              {t("submit")}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
}
